import { Button, FormHelperText, Typography, useMediaQuery } from '@mui/material'
import { useRef } from 'react'
import UploadIcon from '@mui/icons-material/Upload'
import { useSnackbarStore } from '../../store'
import { FILE_SIZE_LIMIT_MB, VIEWPORT_MD } from '../../constants'
import { StringObject } from '../../interfaces'

interface FileUploadButtonProps {
  acceptedTypeExtensions: StringObject
  acceptedTypes: string
  acceptedTypesText?: string
  multiple?: boolean
  disabled?: boolean
  error?: string
  onFileSelect: (files: File[]) => void
}

export const FileUploadButton = ({
  acceptedTypeExtensions,
  acceptedTypes,
  acceptedTypesText,
  multiple = true,
  disabled,
  error,
  onFileSelect,
}: FileUploadButtonProps) => {
  const matches = useMediaQuery(VIEWPORT_MD)
  const showSnackbar = useSnackbarStore((state) => state.showSnackbar)

  const fileUploadRef = useRef<HTMLInputElement>(null)

  const selectFiles = () => {
    fileUploadRef && fileUploadRef.current?.click()
  }

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const newFiles: File[] = []

      for (const file of Array.from(event.target.files)) {
        const extension = file.name.split('.').pop()?.toLowerCase()

        if (!extension) {
          continue
        }

        if (file.size / 1024 / 1024 > FILE_SIZE_LIMIT_MB) {
          showSnackbar(`${file.name} netika pievienots. Datnes izmērs pārsniedz atļauto!`, { severity: 'warning' })

          continue
        }

        if (acceptedTypeExtensions[extension] == null || acceptedTypeExtensions[extension] !== file.type) {
          showSnackbar(`${file.name} netika pievienots. Nederīgs datnes formāts!`, { severity: 'warning' })

          continue
        }

        newFiles.push(file)
      }

      if (newFiles.length) {
        onFileSelect(newFiles)

        // clear selected files to allow duplicate selection
        if (fileUploadRef.current) {
          fileUploadRef.current.value = ''
        }
      }
    }
  }

  return (
    <>
      <input ref={fileUploadRef} type="file" multiple={multiple} accept={acceptedTypes} hidden onChange={onChange}></input>
      <Button
        variant="contained"
        color="primary"
        size="large"
        sx={{
          mr: matches ? 'auto' : undefined,
        }}
        disabled={disabled}
        onClick={selectFiles}
      >
        {multiple ? 'Pievienot attēlus' : 'Pievienot attēlu'} <UploadIcon />
      </Button>
      {error && <FormHelperText sx={{ ml: '14px' }}>{error}</FormHelperText>}
      <Typography sx={{ color: '#999' }}>{acceptedTypesText}</Typography>
    </>
  )
}

import { useSvgParams } from '../../hooks'
import { SvgIcon } from '@mui/material'
import { SvgIconsProps } from '../../interfaces'

export const EventIcon = ({ color, size, sx }: SvgIconsProps) => {
  const [dimension, fill] = useSvgParams({ size, color })

  return (
    <SvgIcon viewBox="0 0 66 66" sx={[{ width: dimension, height: dimension }, sx || {}]}>
      <path
        fill={fill}
        d="M46.06 15.7h-2.1v-1.02c0-1.24-1.01-2.25-2.25-2.25s-2.25 1.01-2.25 2.25v1.02H26.55v-1.02c0-1.24-1.01-2.25-2.25-2.25s-2.25 1.01-2.25 2.25v1.02h-2.1c-3.64 0-6.6 2.96-6.6 6.6v25.03c0 3.64 2.96 6.6 6.6 6.6h26.12c3.64 0 6.6-2.96 6.6-6.6V22.3c0-3.64-2.96-6.6-6.6-6.6zm-26.12 4.5h2.1v1.01c0 1.24 1.01 2.25 2.25 2.25s2.25-1.01 2.25-2.25V20.2h12.91v1.01c0 1.24 1.01 2.25 2.25 2.25s2.25-1.01 2.25-2.25V20.2h2.1c1.16 0 2.1.94 2.1 2.1v4.28H17.84V22.3c0-1.16.94-2.1 2.1-2.1zm26.12 29.23H19.94a2.1 2.1 0 0 1-2.1-2.1V31.08h30.32v16.25a2.1 2.1 0 0 1-2.1 2.1z"
      />
    </SvgIcon>
  )
}

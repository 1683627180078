export const MAX_LENGTH_ERROR = 'Sasniegts maksimālais garums'

export const INVALID_DATE_TIME_FORMAT = 'Nekorekts datuma un/vai laika formāts'

export const REQUIRED_ERROR_MESSAGE = 'Obligāts lauks'

export const GENERIC_ERROR = 'Notikusi kļūda! Lūdzu mēģini vēlreiz'

export const DATE_FROM_BEFORE_TO = 'Datums un laiks no jābūt pirms datums un laiks līdz'

export const DATE_TIME_IN_PAST = 'Datums un laiks nevar būt pagātnē'

export const REQUIRED_MUNICIPALITIES = 'Nav izvēlēta neviena pašvaldība'

import { useState } from 'react'
import { Box, Drawer } from '@mui/material'
import { Outlet } from 'react-router-dom'
import { Header, Menu, SafeAreaWrap, PageWrap } from '../../components'

export interface LayoutProps {
  logo?: JSX.Element
  children?: JSX.Element | JSX.Element[]
}

export const Layout = ({ logo, children }: LayoutProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const onMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  return (
    <Box sx={{ height: '100%' }}>
      <Header onMenuToggle={onMenuToggle} logo={logo} />
      <Drawer anchor="right" open={isMenuOpen} onClose={onMenuToggle}>
        <SafeAreaWrap sx={{ height: '100%' }}>
          <Menu onClose={onMenuToggle} />
        </SafeAreaWrap>
      </Drawer>
      <Box
        sx={{
          paddingRight: 'env(safe-area-inset-right, 0)',
          paddingBottom: 'env(safe-area-inset-bottom, 0)',
          paddingLeft: 'env(safe-area-inset-left, 0)',
          height: 'calc(100% - 72px)',
          maxWidth: '1400px',
          margin: '0 auto',
        }}
      >
        <PageWrap useMinHeight={false}>{children || <Outlet />}</PageWrap>
      </Box>
    </Box>
  )
}

import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'

export const QrButton = () => {
  const navigate = useNavigate()

  const onScanner = () => {
    navigate('/scanner')
  }

  return (
    <Button variant="contained" onClick={onScanner} sx={{ mt: 'auto' }} size="large">
      Skenēt QR kodu
    </Button>
  )
}

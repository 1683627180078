import React, { FormEvent, useState } from 'react'
import { Alert, Box, Button, TextField, Typography } from '@mui/material'
import { FormRow } from '../../../style/styled-components'
import { useNavigate, useParams } from 'react-router-dom'
import { useAddCredentialsMutation } from '../../../graphql'
import { DelayedLoader } from '../../../components'

interface FormData {
  username: string
  password: string
  passwordRep: string
}

export const UserForm = () => {
  const { id } = useParams()

  const navigate = useNavigate()

  const [formData, setFormData] = useState<FormData>({
    username: '',
    password: '',
    passwordRep: '',
  })

  const [errors, setErrors] = useState<string[]>()

  const [addCredentialsMutation, { loading: loadingAdd }] = useAddCredentialsMutation()

  const validateForm = () => {
    let tempErrors: string[] = []

    if (formData.username.length < 4) {
      tempErrors.push('Lietotājvārdam ir jābūt garākam par 4 simboliem')
    }

    if (formData.password.length < 5) {
      tempErrors.push('Parolei jābūt vismaz 5 simbolus garai!')
    }

    if (formData.password !== formData.passwordRep) {
      tempErrors.push('Norādītās paroles nav vienādas!')
    }
    setErrors(tempErrors)
    return tempErrors.length > 0 ? false : true
  }

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault()

    if (validateForm()) {
      addCredentialsMutation({ variables: { username: formData.username, password: formData.password } }).then(() => {
        goBack()
      })
    }
  }

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    })
  }

  const goBack = () => {
    navigate(-1)
  }

  if (id && loadingAdd) {
    return <DelayedLoader />
  }

  return (
    <>
      {errors && <Alert severity="error">{errors.join(' ')}</Alert>}
      <Box component="form" onSubmit={onSubmit}>
        <FormRow component="label" sx={{ mt: 0 }}>
          <Typography component="span">Lietotājvārds</Typography>
          <TextField onChange={onChange} name="username" value={formData.username} />
        </FormRow>
        <FormRow component="label" sx={{ mt: 0 }}>
          <Typography component="span">Parole</Typography>
          <TextField onChange={onChange} type="password" name="password" value={formData.password} />
        </FormRow>
        <FormRow component="label" sx={{ mt: 0 }}>
          <Typography component="span">Parole atkārtoti</Typography>
          <TextField onChange={onChange} type="password" name="passwordRep" value={formData.passwordRep} />
        </FormRow>
        <FormRow>
          <Button variant="contained" type="submit">
            Saglabāt
          </Button>
        </FormRow>
        <FormRow>
          <Button variant="outlined" onClick={goBack}>
            Atpakaļ
          </Button>
        </FormRow>
      </Box>
    </>
  )
}

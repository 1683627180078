import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Duration: any;
  JSON: any;
  JsonObject: any;
  Long: any;
  Timestamp: any;
  Void: any;
};

export type AddCredentialsOutputUna = {
  __typename?: 'AddCredentialsOutputUna';
  /**  Inconsequential. */
  expiresAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
};

/** # TYPES. */
export type AddressLocationAnn = LocationAnn & {
  __typename?: 'AddressLocationAnn';
  address: Scalars['String'];
};

/** # TYPES. */
export type AddressLocationCln = LocationCln & {
  __typename?: 'AddressLocationCln';
  address: Scalars['String'];
};

/** # TYPES. */
export type AddressLocationCmp = LocationCmp & {
  __typename?: 'AddressLocationCmp';
  address: Scalars['String'];
};

/** # TYPES. */
export type AddressLocationLyl = LocationLyl & {
  __typename?: 'AddressLocationLyl';
  address: Scalars['String'];
};

/** # TYPES. */
export type AddressLocationMnc = LocationMnc & {
  __typename?: 'AddressLocationMnc';
  address: Scalars['String'];
};

/** # TYPES. */
export type AddressLocationMsg = LocationMsg & {
  __typename?: 'AddressLocationMsg';
  address: Scalars['String'];
};

/** # TYPES. */
export type AddressLocationNtf = LocationNtf & {
  __typename?: 'AddressLocationNtf';
  address: Scalars['String'];
};

/** # TYPES. */
export type AddressLocationNws = LocationNws & {
  __typename?: 'AddressLocationNws';
  address: Scalars['String'];
};

/** # TYPES. */
export type AddressLocationPev = LocationPev & {
  __typename?: 'AddressLocationPev';
  address: Scalars['String'];
};

/** # TYPES. */
export type AddressLocationPrp = LocationPrp & {
  __typename?: 'AddressLocationPrp';
  address: Scalars['String'];
};

/** # TYPES. */
export type AddressLocationSbm = LocationSbm & {
  __typename?: 'AddressLocationSbm';
  address: Scalars['String'];
};

/** # TYPES. */
export type AddressLocationTgs = LocationTgs & {
  __typename?: 'AddressLocationTgs';
  address: Scalars['String'];
};

/** # TYPES. */
export type AddressLocationTrs = LocationTrs & {
  __typename?: 'AddressLocationTrs';
  address: Scalars['String'];
};

/** # TYPES. */
export type AddressLocationUsr = LocationUsr & {
  __typename?: 'AddressLocationUsr';
  address: Scalars['String'];
};

export type AnnouncementInputAnn = {
  link?: InputMaybe<MessageLinkInputAnn>;
  message: MessageInputAnn;
  restrictedTargets?: InputMaybe<Array<TargetInputAnn>>;
};

export type AnnouncementOutputAnn = {
  __typename?: 'AnnouncementOutputAnn';
  id: Scalars['ID'];
  link?: Maybe<MessageLinkOutputAnn>;
  message: MessageOutputAnn;
  /**  Only relevant to resident-application clients. Always null otherwise. */
  viewedAt?: Maybe<Scalars['DateTime']>;
};

export type AnnouncementSendOutputAnn = {
  __typename?: 'AnnouncementSendOutputAnn';
  announcement: AnnouncementOutputAnn;
  recipientUserCount: Scalars['Int'];
};

export type AnnouncementsMutations = {
  __typename?: 'AnnouncementsMutations';
  send: AnnouncementSendOutputAnn;
  setViewed: AnnouncementOutputAnn;
};


export type AnnouncementsMutationsSendArgs = {
  input: AnnouncementInputAnn;
};


export type AnnouncementsMutationsSetViewedArgs = {
  announcementId: Scalars['ID'];
  viewed: Scalars['Boolean'];
};

export type AnnouncementsQueries = {
  __typename?: 'AnnouncementsQueries';
  getAnnouncement: AnnouncementOutputAnn;
  getAnnouncements: Array<AnnouncementOutputAnn>;
  helloWorld: Scalars['String'];
};


export type AnnouncementsQueriesGetAnnouncementArgs = {
  id: Scalars['ID'];
};

export type ApprovalOutputLyl = {
  __typename?: 'ApprovalOutputLyl';
  approvedAt?: Maybe<Scalars['DateTime']>;
  municipalityId: Scalars['ID'];
};

export type AttachmentOutputAnn = {
  __typename?: 'AttachmentOutputAnn';
  contentType: Scalars['String'];
  fileId: Scalars['ID'];
  name: Scalars['String'];
  size: Scalars['Int'];
};

export type AttachmentOutputCln = {
  __typename?: 'AttachmentOutputCln';
  contentType: Scalars['String'];
  fileId: Scalars['ID'];
  name: Scalars['String'];
  size: Scalars['Int'];
};

export type AttachmentOutputCmp = {
  __typename?: 'AttachmentOutputCmp';
  contentType: Scalars['String'];
  fileId: Scalars['ID'];
  name: Scalars['String'];
  size: Scalars['Int'];
};

export type AttachmentOutputLyl = {
  __typename?: 'AttachmentOutputLyl';
  contentType: Scalars['String'];
  fileId: Scalars['ID'];
  name: Scalars['String'];
  size: Scalars['Int'];
};

export type AttachmentOutputMnc = {
  __typename?: 'AttachmentOutputMnc';
  contentType: Scalars['String'];
  fileId: Scalars['ID'];
  name: Scalars['String'];
  size: Scalars['Int'];
};

export type AttachmentOutputMsg = {
  __typename?: 'AttachmentOutputMsg';
  contentType: Scalars['String'];
  fileId: Scalars['ID'];
  name: Scalars['String'];
  size: Scalars['Int'];
};

export type AttachmentOutputNtf = {
  __typename?: 'AttachmentOutputNtf';
  contentType: Scalars['String'];
  fileId: Scalars['ID'];
  name: Scalars['String'];
  size: Scalars['Int'];
};

export type AttachmentOutputNws = {
  __typename?: 'AttachmentOutputNws';
  contentType: Scalars['String'];
  fileId: Scalars['ID'];
  name: Scalars['String'];
  size: Scalars['Int'];
};

export type AttachmentOutputPev = {
  __typename?: 'AttachmentOutputPev';
  contentType: Scalars['String'];
  fileId: Scalars['ID'];
  name: Scalars['String'];
  size: Scalars['Int'];
};

export type AttachmentOutputPrp = {
  __typename?: 'AttachmentOutputPrp';
  contentType: Scalars['String'];
  fileId: Scalars['ID'];
  name: Scalars['String'];
  size: Scalars['Int'];
};

export type AttachmentOutputSbm = {
  __typename?: 'AttachmentOutputSbm';
  contentType: Scalars['String'];
  fileId: Scalars['ID'];
  name: Scalars['String'];
  size: Scalars['Int'];
};

export type AttachmentOutputTgs = {
  __typename?: 'AttachmentOutputTgs';
  contentType: Scalars['String'];
  fileId: Scalars['ID'];
  name: Scalars['String'];
  size: Scalars['Int'];
};

export type AttachmentOutputTrs = {
  __typename?: 'AttachmentOutputTrs';
  contentType: Scalars['String'];
  fileId: Scalars['ID'];
  name: Scalars['String'];
  size: Scalars['Int'];
};

export type AttachmentOutputUsr = {
  __typename?: 'AttachmentOutputUsr';
  contentType: Scalars['String'];
  fileId: Scalars['ID'];
  name: Scalars['String'];
  size: Scalars['Int'];
};

export type AuthenticateInputPna = {
  code: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type AuthenticateOutputPna = {
  __typename?: 'AuthenticateOutputPna';
  /**  Null on unsuccessful attempt. */
  token?: Maybe<Scalars['ID']>;
};

export type AuthenticateOutputUna = {
  __typename?: 'AuthenticateOutputUna';
  /**  Null on unsuccessful attempt. */
  token?: Maybe<Scalars['ID']>;
};

export type AuthenticationClientOutput = {
  __typename?: 'AuthenticationClientOutput';
  clientPlatform: ClientPlatformUsr;
  /**  Actual id or id of administrated resident application. */
  effectiveId: Scalars['ID'];
  /**  Municipality or merchant id. */
  tenantId: Scalars['ID'];
  /**  Always the actual id. */
  underlyingId: Scalars['ID'];
};

/** # ENUMS. */
export enum AuthenticationMethodAnn {
  LatvijaLv = 'LATVIJA_LV',
  PhoneNumber = 'PHONE_NUMBER',
  UsernamePassword = 'USERNAME_PASSWORD'
}

/** # ENUMS. */
export enum AuthenticationMethodCmp {
  LatvijaLv = 'LATVIJA_LV',
  PhoneNumber = 'PHONE_NUMBER',
  UsernamePassword = 'USERNAME_PASSWORD'
}

/** # ENUMS. */
export enum AuthenticationMethodLyl {
  LatvijaLv = 'LATVIJA_LV',
  PhoneNumber = 'PHONE_NUMBER',
  UsernamePassword = 'USERNAME_PASSWORD',
  ZzDatsIgp = 'ZZ_DATS_IGP'
}

/** # ENUMS. */
export enum AuthenticationMethodMnc {
  LatvijaLv = 'LATVIJA_LV',
  PhoneNumber = 'PHONE_NUMBER',
  UsernamePassword = 'USERNAME_PASSWORD',
  ZzDatsIgp = 'ZZ_DATS_IGP'
}

/** # ENUMS. */
export enum AuthenticationMethodMsg {
  LatvijaLv = 'LATVIJA_LV',
  PhoneNumber = 'PHONE_NUMBER',
  UsernamePassword = 'USERNAME_PASSWORD',
  ZzDatsIgp = 'ZZ_DATS_IGP'
}

/** # ENUMS. */
export enum AuthenticationMethodNtf {
  LatvijaLv = 'LATVIJA_LV',
  PhoneNumber = 'PHONE_NUMBER',
  UsernamePassword = 'USERNAME_PASSWORD'
}

/** # ENUMS. */
export enum AuthenticationMethodNws {
  LatvijaLv = 'LATVIJA_LV',
  PhoneNumber = 'PHONE_NUMBER',
  UsernamePassword = 'USERNAME_PASSWORD',
  ZzDatsIgp = 'ZZ_DATS_IGP'
}

/** # ENUMS. */
export enum AuthenticationMethodPev {
  LatvijaLv = 'LATVIJA_LV',
  PhoneNumber = 'PHONE_NUMBER',
  UsernamePassword = 'USERNAME_PASSWORD',
  ZzDatsIgp = 'ZZ_DATS_IGP'
}

/** # ENUMS. */
export enum AuthenticationMethodPrp {
  LatvijaLv = 'LATVIJA_LV',
  PhoneNumber = 'PHONE_NUMBER',
  UsernamePassword = 'USERNAME_PASSWORD'
}

/** # ENUMS. */
export enum AuthenticationMethodSbm {
  LatvijaLv = 'LATVIJA_LV',
  PhoneNumber = 'PHONE_NUMBER',
  UsernamePassword = 'USERNAME_PASSWORD'
}

/** # ENUMS. */
export enum AuthenticationMethodTgs {
  LatvijaLv = 'LATVIJA_LV',
  PhoneNumber = 'PHONE_NUMBER',
  UsernamePassword = 'USERNAME_PASSWORD',
  ZzDatsIgp = 'ZZ_DATS_IGP'
}

/** # ENUMS. */
export enum AuthenticationMethodTrs {
  LatvijaLv = 'LATVIJA_LV',
  PhoneNumber = 'PHONE_NUMBER',
  UsernamePassword = 'USERNAME_PASSWORD',
  ZzDatsIgp = 'ZZ_DATS_IGP'
}

/** # ENUMS. */
export enum AuthenticationMethodUsr {
  LatvijaLv = 'LATVIJA_LV',
  PhoneNumber = 'PHONE_NUMBER',
  UsernamePassword = 'USERNAME_PASSWORD',
  ZzDatsIgp = 'ZZ_DATS_IGP'
}

export type ClientOutputAnn = {
  __typename?: 'ClientOutputAnn';
  id: Scalars['ID'];
  merchantId?: Maybe<Scalars['ID']>;
  municipalityId?: Maybe<Scalars['ID']>;
  platform: ClientPlatformAnn;
};

export type ClientOutputCln = {
  __typename?: 'ClientOutputCln';
  id: Scalars['ID'];
  merchantId?: Maybe<Scalars['ID']>;
  municipalityId?: Maybe<Scalars['ID']>;
  platform: ClientPlatformCln;
};

export type ClientOutputCmp = {
  __typename?: 'ClientOutputCmp';
  id: Scalars['ID'];
  merchantId?: Maybe<Scalars['ID']>;
  municipalityId?: Maybe<Scalars['ID']>;
  platform: ClientPlatformCmp;
};

export type ClientOutputLyl = {
  __typename?: 'ClientOutputLyl';
  id: Scalars['ID'];
  merchantId?: Maybe<Scalars['ID']>;
  municipalityId?: Maybe<Scalars['ID']>;
  platform: ClientPlatformLyl;
};

export type ClientOutputMnc = {
  __typename?: 'ClientOutputMnc';
  id: Scalars['ID'];
  merchantId?: Maybe<Scalars['ID']>;
  municipalityId?: Maybe<Scalars['ID']>;
  platform: ClientPlatformMnc;
};

export type ClientOutputMsg = {
  __typename?: 'ClientOutputMsg';
  id: Scalars['ID'];
  merchantId?: Maybe<Scalars['ID']>;
  municipalityId?: Maybe<Scalars['ID']>;
  platform: ClientPlatformMsg;
};

export type ClientOutputNtf = {
  __typename?: 'ClientOutputNtf';
  id: Scalars['ID'];
  merchantId?: Maybe<Scalars['ID']>;
  municipalityId?: Maybe<Scalars['ID']>;
  platform: ClientPlatformNtf;
};

export type ClientOutputNws = {
  __typename?: 'ClientOutputNws';
  id: Scalars['ID'];
  merchantId?: Maybe<Scalars['ID']>;
  municipalityId?: Maybe<Scalars['ID']>;
  platform: ClientPlatformNws;
};

export type ClientOutputPev = {
  __typename?: 'ClientOutputPev';
  id: Scalars['ID'];
  merchantId?: Maybe<Scalars['ID']>;
  municipalityId?: Maybe<Scalars['ID']>;
  platform: ClientPlatformPev;
};

export type ClientOutputPrp = {
  __typename?: 'ClientOutputPrp';
  id: Scalars['ID'];
  merchantId?: Maybe<Scalars['ID']>;
  municipalityId?: Maybe<Scalars['ID']>;
  platform: ClientPlatformPrp;
};

export type ClientOutputSbm = {
  __typename?: 'ClientOutputSbm';
  id: Scalars['ID'];
  merchantId?: Maybe<Scalars['ID']>;
  municipalityId?: Maybe<Scalars['ID']>;
  platform: ClientPlatformSbm;
};

export type ClientOutputTgs = {
  __typename?: 'ClientOutputTgs';
  id: Scalars['ID'];
  merchantId?: Maybe<Scalars['ID']>;
  municipalityId?: Maybe<Scalars['ID']>;
  platform: ClientPlatformTgs;
};

export type ClientOutputTrs = {
  __typename?: 'ClientOutputTrs';
  id: Scalars['ID'];
  merchantId?: Maybe<Scalars['ID']>;
  municipalityId?: Maybe<Scalars['ID']>;
  platform: ClientPlatformTrs;
};

export type ClientOutputUsr = {
  __typename?: 'ClientOutputUsr';
  id: Scalars['ID'];
  merchantId?: Maybe<Scalars['ID']>;
  municipalityId?: Maybe<Scalars['ID']>;
  platform: ClientPlatformUsr;
};

export enum ClientPlatformAnn {
  AdministrationApplication = 'ADMINISTRATION_APPLICATION',
  MerchantApplication = 'MERCHANT_APPLICATION',
  ResidentApplication = 'RESIDENT_APPLICATION',
  ZzDatsIgp = 'ZZ_DATS_IGP',
  ZzDatsLocalIntegrationConsumer = 'ZZ_DATS_LOCAL_INTEGRATION_CONSUMER'
}

/** # ENUMS. */
export enum ClientPlatformCln {
  AdministrationApplication = 'ADMINISTRATION_APPLICATION',
  MerchantApplication = 'MERCHANT_APPLICATION',
  ResidentApplication = 'RESIDENT_APPLICATION',
  ZzDatsIgp = 'ZZ_DATS_IGP',
  ZzDatsLocalIntegrationConsumer = 'ZZ_DATS_LOCAL_INTEGRATION_CONSUMER'
}

export enum ClientPlatformCmp {
  AdministrationApplication = 'ADMINISTRATION_APPLICATION',
  MerchantApplication = 'MERCHANT_APPLICATION',
  ResidentApplication = 'RESIDENT_APPLICATION',
  ZzDatsIgp = 'ZZ_DATS_IGP',
  ZzDatsLocalIntegrationConsumer = 'ZZ_DATS_LOCAL_INTEGRATION_CONSUMER'
}

export enum ClientPlatformLyl {
  AdministrationApplication = 'ADMINISTRATION_APPLICATION',
  MerchantApplication = 'MERCHANT_APPLICATION',
  ResidentApplication = 'RESIDENT_APPLICATION',
  ZzDatsIgp = 'ZZ_DATS_IGP',
  ZzDatsLocalIntegrationConsumer = 'ZZ_DATS_LOCAL_INTEGRATION_CONSUMER'
}

export enum ClientPlatformMnc {
  AdministrationApplication = 'ADMINISTRATION_APPLICATION',
  MerchantApplication = 'MERCHANT_APPLICATION',
  ResidentApplication = 'RESIDENT_APPLICATION',
  ZzDatsIgp = 'ZZ_DATS_IGP',
  ZzDatsLocalIntegrationConsumer = 'ZZ_DATS_LOCAL_INTEGRATION_CONSUMER'
}

export enum ClientPlatformMsg {
  AdministrationApplication = 'ADMINISTRATION_APPLICATION',
  MerchantApplication = 'MERCHANT_APPLICATION',
  ResidentApplication = 'RESIDENT_APPLICATION',
  ZzDatsIgp = 'ZZ_DATS_IGP',
  ZzDatsLocalIntegrationConsumer = 'ZZ_DATS_LOCAL_INTEGRATION_CONSUMER'
}

export enum ClientPlatformNtf {
  AdministrationApplication = 'ADMINISTRATION_APPLICATION',
  MerchantApplication = 'MERCHANT_APPLICATION',
  ResidentApplication = 'RESIDENT_APPLICATION',
  ZzDatsIgp = 'ZZ_DATS_IGP',
  ZzDatsLocalIntegrationConsumer = 'ZZ_DATS_LOCAL_INTEGRATION_CONSUMER'
}

export enum ClientPlatformNws {
  AdministrationApplication = 'ADMINISTRATION_APPLICATION',
  MerchantApplication = 'MERCHANT_APPLICATION',
  ResidentApplication = 'RESIDENT_APPLICATION',
  ZzDatsIgp = 'ZZ_DATS_IGP',
  ZzDatsLocalIntegrationConsumer = 'ZZ_DATS_LOCAL_INTEGRATION_CONSUMER'
}

export enum ClientPlatformPev {
  AdministrationApplication = 'ADMINISTRATION_APPLICATION',
  MerchantApplication = 'MERCHANT_APPLICATION',
  ResidentApplication = 'RESIDENT_APPLICATION',
  ZzDatsIgp = 'ZZ_DATS_IGP',
  ZzDatsLocalIntegrationConsumer = 'ZZ_DATS_LOCAL_INTEGRATION_CONSUMER'
}

export enum ClientPlatformPrp {
  AdministrationApplication = 'ADMINISTRATION_APPLICATION',
  MerchantApplication = 'MERCHANT_APPLICATION',
  ResidentApplication = 'RESIDENT_APPLICATION',
  ZzDatsIgp = 'ZZ_DATS_IGP',
  ZzDatsLocalIntegrationConsumer = 'ZZ_DATS_LOCAL_INTEGRATION_CONSUMER'
}

export enum ClientPlatformSbm {
  AdministrationApplication = 'ADMINISTRATION_APPLICATION',
  MerchantApplication = 'MERCHANT_APPLICATION',
  ResidentApplication = 'RESIDENT_APPLICATION',
  ZzDatsIgp = 'ZZ_DATS_IGP',
  ZzDatsLocalIntegrationConsumer = 'ZZ_DATS_LOCAL_INTEGRATION_CONSUMER'
}

export enum ClientPlatformTgs {
  AdministrationApplication = 'ADMINISTRATION_APPLICATION',
  MerchantApplication = 'MERCHANT_APPLICATION',
  ResidentApplication = 'RESIDENT_APPLICATION',
  ZzDatsIgp = 'ZZ_DATS_IGP',
  ZzDatsLocalIntegrationConsumer = 'ZZ_DATS_LOCAL_INTEGRATION_CONSUMER'
}

export enum ClientPlatformTrs {
  AdministrationApplication = 'ADMINISTRATION_APPLICATION',
  MerchantApplication = 'MERCHANT_APPLICATION',
  ResidentApplication = 'RESIDENT_APPLICATION',
  ZzDatsIgp = 'ZZ_DATS_IGP',
  ZzDatsLocalIntegrationConsumer = 'ZZ_DATS_LOCAL_INTEGRATION_CONSUMER'
}

export enum ClientPlatformUsr {
  AdministrationApplication = 'ADMINISTRATION_APPLICATION',
  MerchantApplication = 'MERCHANT_APPLICATION',
  ResidentApplication = 'RESIDENT_APPLICATION',
  ZzDatsIgp = 'ZZ_DATS_IGP',
  ZzDatsLocalIntegrationConsumer = 'ZZ_DATS_LOCAL_INTEGRATION_CONSUMER'
}

export type CommonUserParametersOutput = {
  __typename?: 'CommonUserParametersOutput';
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  personalCode?: Maybe<Scalars['String']>;
};

export type ComplaintInputCmp = {
  location: LocationInputCmp;
  message: MessageInputCmp;
};

export type ComplaintOutputCmp = {
  __typename?: 'ComplaintOutputCmp';
  closedAt?: Maybe<Scalars['DateTime']>;
  configuration?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isResolved?: Maybe<Scalars['Boolean']>;
  location: LocationCmp;
  message: MessageOutputCmp;
  openedAt?: Maybe<Scalars['DateTime']>;
  status: ComplaintStatusCmp;
};

export enum ComplaintStatusCmp {
  /**  The municipality has replied to the complaint. */
  Closed = 'CLOSED',
  /**  The municipality has closed the complaint and marked it as resolved. */
  Deleted = 'DELETED',
  New = 'NEW',
  /**  Complaint has been created. */
  Open = 'OPEN',
  /**  The municipality has confirmed the complaint. */
  Replied = 'REPLIED',
  /**  The municipality has closed the complaint without marking it as resolved. */
  Resolved = 'RESOLVED'
}

export type ComplaintsMutations = {
  __typename?: 'ComplaintsMutations';
  addComplaint: ComplaintOutputCmp;
  closeComplaint: ComplaintOutputCmp;
  deleteComplaint: Scalars['ID'];
  editComplaintContent: ComplaintOutputCmp;
  openComplaint: ComplaintOutputCmp;
  resolveComplaint: ComplaintOutputCmp;
};


export type ComplaintsMutationsAddComplaintArgs = {
  input: ComplaintInputCmp;
};


export type ComplaintsMutationsCloseComplaintArgs = {
  id: Scalars['ID'];
};


export type ComplaintsMutationsDeleteComplaintArgs = {
  id: Scalars['ID'];
};


export type ComplaintsMutationsEditComplaintContentArgs = {
  id: Scalars['ID'];
  input: ComplaintInputCmp;
};


export type ComplaintsMutationsOpenComplaintArgs = {
  id: Scalars['ID'];
};


export type ComplaintsMutationsResolveComplaintArgs = {
  id: Scalars['ID'];
};

export type ComplexId = {
  entity: Scalars['ID'];
  module: Scalars['String'];
};

export type CoordinatesLocationAnn = LocationAnn & {
  __typename?: 'CoordinatesLocationAnn';
  address?: Maybe<Scalars['String']>;
  elevation?: Maybe<Scalars['Float']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type CoordinatesLocationCln = LocationCln & {
  __typename?: 'CoordinatesLocationCln';
  address?: Maybe<Scalars['String']>;
  elevation?: Maybe<Scalars['Float']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type CoordinatesLocationCmp = LocationCmp & {
  __typename?: 'CoordinatesLocationCmp';
  address?: Maybe<Scalars['String']>;
  elevation?: Maybe<Scalars['Float']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type CoordinatesLocationLyl = LocationLyl & {
  __typename?: 'CoordinatesLocationLyl';
  address?: Maybe<Scalars['String']>;
  elevation?: Maybe<Scalars['Float']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type CoordinatesLocationMnc = LocationMnc & {
  __typename?: 'CoordinatesLocationMnc';
  address?: Maybe<Scalars['String']>;
  elevation?: Maybe<Scalars['Float']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type CoordinatesLocationMsg = LocationMsg & {
  __typename?: 'CoordinatesLocationMsg';
  address?: Maybe<Scalars['String']>;
  elevation?: Maybe<Scalars['Float']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type CoordinatesLocationNtf = LocationNtf & {
  __typename?: 'CoordinatesLocationNtf';
  address?: Maybe<Scalars['String']>;
  elevation?: Maybe<Scalars['Float']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type CoordinatesLocationNws = LocationNws & {
  __typename?: 'CoordinatesLocationNws';
  address?: Maybe<Scalars['String']>;
  elevation?: Maybe<Scalars['Float']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type CoordinatesLocationPev = LocationPev & {
  __typename?: 'CoordinatesLocationPev';
  address?: Maybe<Scalars['String']>;
  elevation?: Maybe<Scalars['Float']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type CoordinatesLocationPrp = LocationPrp & {
  __typename?: 'CoordinatesLocationPrp';
  address?: Maybe<Scalars['String']>;
  elevation?: Maybe<Scalars['Float']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type CoordinatesLocationSbm = LocationSbm & {
  __typename?: 'CoordinatesLocationSbm';
  address?: Maybe<Scalars['String']>;
  elevation?: Maybe<Scalars['Float']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type CoordinatesLocationTgs = LocationTgs & {
  __typename?: 'CoordinatesLocationTgs';
  address?: Maybe<Scalars['String']>;
  elevation?: Maybe<Scalars['Float']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type CoordinatesLocationTrs = LocationTrs & {
  __typename?: 'CoordinatesLocationTrs';
  address?: Maybe<Scalars['String']>;
  elevation?: Maybe<Scalars['Float']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type CoordinatesLocationUsr = LocationUsr & {
  __typename?: 'CoordinatesLocationUsr';
  address?: Maybe<Scalars['String']>;
  elevation?: Maybe<Scalars['Float']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type CredentialsInputUna = {
  password: Scalars['String'];
  username: Scalars['String'];
};

export type CryptoQueries = {
  __typename?: 'CryptoQueries';
  helloWorld: Scalars['String'];
  loyalty: LoyaltyCryptoQueries;
};

export enum DeliveryPriorityNtf {
  Immediate = 'IMMEDIATE',
  PowerSaving = 'POWER_SAVING'
}

export type DestinationInputTrs = {
  isDisabled: Scalars['Boolean'];
  location: LocationInputTrs;
  message: MessageInputTrs;
  primaryTagId: Scalars['ID'];
};

export type DestinationOutputTrs = {
  __typename?: 'DestinationOutputTrs';
  id: Scalars['ID'];
  isDisabled: Scalars['Boolean'];
  location: LocationTrs;
  message: MessageOutputTrs;
};

export type GenerateCodeInputPna = {
  isSendoutSkippable?: InputMaybe<Scalars['Boolean']>;
  phoneNumber: Scalars['String'];
};

export type GenerateCodeOutputPna = {
  __typename?: 'GenerateCodeOutputPna';
  expiresAt: Scalars['DateTime'];
  hasSkippedSendout: Scalars['Boolean'];
};

export type GroupOutputLyl = {
  __typename?: 'GroupOutputLyl';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  shortId: Scalars['Int'];
};

export type HttpEndpoint = {
  __typename?: 'HttpEndpoint';
  authorizationExpression?: Maybe<Scalars['String']>;
  method: HttpMethod;
  path: Scalars['String'];
};

export enum HttpMethod {
  Delete = 'DELETE',
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT'
}

export type IgpAuthenticatorQueries = {
  __typename?: 'IgpAuthenticatorQueries';
  helloWorld: Scalars['String'];
};

export type ListFilter = {
  and?: InputMaybe<ListFilter>;
  approximate?: Scalars['Boolean'];
  flip?: Scalars['Boolean'];
  key: Scalars['String'];
  or?: InputMaybe<ListFilter>;
  value: Scalars['String'];
};

export type ListLookup = {
  filter?: InputMaybe<ListFilter>;
  limit?: Scalars['Int'];
  orderAscending?: Scalars['Boolean'];
  orderBy?: Scalars['String'];
  skip?: Scalars['Int'];
};

/** # INTERFACES. */
export type LocationAnn = {
  address?: Maybe<Scalars['String']>;
};

/** # INTERFACES. */
export type LocationCln = {
  address?: Maybe<Scalars['String']>;
};

/** # INTERFACES. */
export type LocationCmp = {
  address?: Maybe<Scalars['String']>;
};

/**
 * # INPUTS.
 *  Either address or coordinates must be provided.
 */
export type LocationInputAnn = {
  address?: InputMaybe<Scalars['String']>;
  elevation?: InputMaybe<Scalars['Float']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
};

/**
 * # INPUTS.
 *  Either address or coordinates must be provided.
 */
export type LocationInputCln = {
  address?: InputMaybe<Scalars['String']>;
  elevation?: InputMaybe<Scalars['Float']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
};

/**
 * # INPUTS.
 *  Either address or coordinates must be provided.
 */
export type LocationInputCmp = {
  address?: InputMaybe<Scalars['String']>;
  elevation?: InputMaybe<Scalars['Float']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
};

/**
 * # INPUTS.
 *  Either address or coordinates must be provided.
 */
export type LocationInputLyl = {
  address?: InputMaybe<Scalars['String']>;
  elevation?: InputMaybe<Scalars['Float']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
};

/**
 * # INPUTS.
 *  Either address or coordinates must be provided.
 */
export type LocationInputMnc = {
  address?: InputMaybe<Scalars['String']>;
  elevation?: InputMaybe<Scalars['Float']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
};

/**
 * # INPUTS.
 *  Either address or coordinates must be provided.
 */
export type LocationInputMsg = {
  address?: InputMaybe<Scalars['String']>;
  elevation?: InputMaybe<Scalars['Float']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
};

/**
 * # INPUTS.
 *  Either address or coordinates must be provided.
 */
export type LocationInputNtf = {
  address?: InputMaybe<Scalars['String']>;
  elevation?: InputMaybe<Scalars['Float']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
};

/**
 * # INPUTS.
 *  Either address or coordinates must be provided.
 */
export type LocationInputNws = {
  address?: InputMaybe<Scalars['String']>;
  elevation?: InputMaybe<Scalars['Float']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
};

/**
 * # INPUTS.
 *  Either address or coordinates must be provided.
 */
export type LocationInputPev = {
  address?: InputMaybe<Scalars['String']>;
  elevation?: InputMaybe<Scalars['Float']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
};

/**
 * # INPUTS.
 *  Either address or coordinates must be provided.
 */
export type LocationInputPrp = {
  address?: InputMaybe<Scalars['String']>;
  elevation?: InputMaybe<Scalars['Float']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
};

/**
 * # INPUTS.
 *  Either address or coordinates must be provided.
 */
export type LocationInputSbm = {
  address?: InputMaybe<Scalars['String']>;
  elevation?: InputMaybe<Scalars['Float']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
};

/**
 * # INPUTS.
 *  Either address or coordinates must be provided.
 */
export type LocationInputTgs = {
  address?: InputMaybe<Scalars['String']>;
  elevation?: InputMaybe<Scalars['Float']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
};

/**
 * # INPUTS.
 *  Either address or coordinates must be provided.
 */
export type LocationInputTrs = {
  address?: InputMaybe<Scalars['String']>;
  elevation?: InputMaybe<Scalars['Float']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
};

/**
 * # INPUTS.
 *  Either address or coordinates must be provided.
 */
export type LocationInputUsr = {
  address?: InputMaybe<Scalars['String']>;
  elevation?: InputMaybe<Scalars['Float']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
};

/** # INTERFACES. */
export type LocationLyl = {
  address?: Maybe<Scalars['String']>;
};

/** # INTERFACES. */
export type LocationMnc = {
  address?: Maybe<Scalars['String']>;
};

/** # INTERFACES. */
export type LocationMsg = {
  address?: Maybe<Scalars['String']>;
};

/** # INTERFACES. */
export type LocationNtf = {
  address?: Maybe<Scalars['String']>;
};

/** # INTERFACES. */
export type LocationNws = {
  address?: Maybe<Scalars['String']>;
};

/** # INTERFACES. */
export type LocationPev = {
  address?: Maybe<Scalars['String']>;
};

/** # INTERFACES. */
export type LocationPrp = {
  address?: Maybe<Scalars['String']>;
};

/** # INTERFACES. */
export type LocationSbm = {
  address?: Maybe<Scalars['String']>;
};

/** # INTERFACES. */
export type LocationTgs = {
  address?: Maybe<Scalars['String']>;
};

/** # INTERFACES. */
export type LocationTrs = {
  address?: Maybe<Scalars['String']>;
};

/** # INTERFACES. */
export type LocationUsr = {
  address?: Maybe<Scalars['String']>;
};

export type LoyaltyCryptoQueries = {
  __typename?: 'LoyaltyCryptoQueries';
  publicKeys: Array<PublicKeyOutput>;
  /**  Substitute every instance of the placeholder (if any) by the current key ID and sign the resulting string. */
  sign: TaggedSignature;
  verify: Scalars['Boolean'];
};


export type LoyaltyCryptoQueriesSignArgs = {
  dataPattern: Scalars['String'];
  keyShortIdPlaceholder?: InputMaybe<Scalars['String']>;
};


export type LoyaltyCryptoQueriesVerifyArgs = {
  input: LoyaltyVerifyInput;
};

export type LoyaltyMutations = {
  __typename?: 'LoyaltyMutations';
  addOffer: OfferOutputLyl;
  /**
   *  NOTE: An offer must be approved by municipality for it to become available to its residents for utilisation.
   *
   */
  approveOffer: OfferOutputLyl;
  deleteOffer: Scalars['ID'];
  /**
   *  NOTE: Only offers, that are yet to be approved by any of the linked municipalities, can be edited. The first
   *  approval by a linked municipality effectively locks offer in place. A municipality unlinking mutation will be
   *  provided later.
   *
   */
  editOffer: OfferOutputLyl;
  forceGroupSync: Array<GroupOutputLyl>;
  /**
   *  NOTE: A revocation is not irreversible - a municipality can decide to approve an offer again after its revocation.
   *
   */
  revokeOfferApproval: OfferOutputLyl;
  /**
   *  Utilize offer by applying it according to QR code data provided by a user of resident application.
   *
   */
  utilizeOffer: Scalars['ID'];
};


export type LoyaltyMutationsAddOfferArgs = {
  input: OfferInputLyl;
};


export type LoyaltyMutationsApproveOfferArgs = {
  id: Scalars['ID'];
};


export type LoyaltyMutationsDeleteOfferArgs = {
  id: Scalars['ID'];
};


export type LoyaltyMutationsEditOfferArgs = {
  id: Scalars['ID'];
  input: OfferInputLyl;
};


export type LoyaltyMutationsRevokeOfferApprovalArgs = {
  id: Scalars['ID'];
};


export type LoyaltyMutationsUtilizeOfferArgs = {
  id: Scalars['ID'];
  input: QrDataInputLyl;
};

export type LoyaltyVerifyInput = {
  data: Scalars['String'];
  keyShortId: Scalars['Int'];
  signature: Scalars['String'];
};

export type LvpSsoAuthenticatorMutations = {
  __typename?: 'LvpSsoAuthenticatorMutations';
  initiate: Scalars['ID'];
};


export type LvpSsoAuthenticatorMutationsInitiateArgs = {
  returnUrl: Scalars['String'];
};

export type LvpSsoAuthenticatorQueries = {
  __typename?: 'LvpSsoAuthenticatorQueries';
  helloWorld: Scalars['String'];
};

export type MerchantOutputAnn = {
  __typename?: 'MerchantOutputAnn';
  configuration?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<MultiStringAnn>;
  id: Scalars['ID'];
  legalAddress?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNumber: Scalars['String'];
};

export type MerchantOutputCln = {
  __typename?: 'MerchantOutputCln';
  configuration?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<MultiStringCln>;
  id: Scalars['ID'];
  legalAddress?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNumber: Scalars['String'];
};

export type MerchantOutputCmp = {
  __typename?: 'MerchantOutputCmp';
  configuration?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<MultiStringCmp>;
  id: Scalars['ID'];
  legalAddress?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNumber: Scalars['String'];
};

export type MerchantOutputLyl = {
  __typename?: 'MerchantOutputLyl';
  configuration?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<MultiStringLyl>;
  id: Scalars['ID'];
  legalAddress?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNumber: Scalars['String'];
};

export type MerchantOutputMnc = {
  __typename?: 'MerchantOutputMnc';
  configuration?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<MultiStringMnc>;
  id: Scalars['ID'];
  legalAddress?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNumber: Scalars['String'];
};

export type MerchantOutputMsg = {
  __typename?: 'MerchantOutputMsg';
  configuration?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<MultiStringMsg>;
  id: Scalars['ID'];
  legalAddress?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNumber: Scalars['String'];
};

export type MerchantOutputNtf = {
  __typename?: 'MerchantOutputNtf';
  configuration?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<MultiStringNtf>;
  id: Scalars['ID'];
  legalAddress?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNumber: Scalars['String'];
};

export type MerchantOutputNws = {
  __typename?: 'MerchantOutputNws';
  configuration?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<MultiStringNws>;
  id: Scalars['ID'];
  legalAddress?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNumber: Scalars['String'];
};

export type MerchantOutputPev = {
  __typename?: 'MerchantOutputPev';
  configuration?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<MultiStringPev>;
  id: Scalars['ID'];
  legalAddress?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNumber: Scalars['String'];
};

export type MerchantOutputPrp = {
  __typename?: 'MerchantOutputPrp';
  configuration?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<MultiStringPrp>;
  id: Scalars['ID'];
  legalAddress?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNumber: Scalars['String'];
};

export type MerchantOutputSbm = {
  __typename?: 'MerchantOutputSbm';
  configuration?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<MultiStringSbm>;
  id: Scalars['ID'];
  legalAddress?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNumber: Scalars['String'];
};

export type MerchantOutputTgs = {
  __typename?: 'MerchantOutputTgs';
  configuration?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<MultiStringTgs>;
  id: Scalars['ID'];
  legalAddress?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNumber: Scalars['String'];
};

export type MerchantOutputTrs = {
  __typename?: 'MerchantOutputTrs';
  configuration?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<MultiStringTrs>;
  id: Scalars['ID'];
  legalAddress?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNumber: Scalars['String'];
};

export type MerchantOutputUsr = {
  __typename?: 'MerchantOutputUsr';
  configuration?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<MultiStringUsr>;
  id: Scalars['ID'];
  legalAddress?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNumber: Scalars['String'];
};

export type MessageInputAnn = {
  attachedFileIds: Array<Scalars['ID']>;
  content: Scalars['JSON'];
  hasNotificationsEnabled?: InputMaybe<Scalars['Boolean']>;
  hasRepliesEnabled?: InputMaybe<Scalars['Boolean']>;
  preview?: InputMaybe<Scalars['JSON']>;
  title?: InputMaybe<Scalars['JSON']>;
};

export type MessageInputCln = {
  attachedFileIds: Array<Scalars['ID']>;
  content: Scalars['JSON'];
  hasRepliesEnabled: Scalars['Boolean'];
  parentId?: InputMaybe<Scalars['ID']>;
  preview?: InputMaybe<Scalars['JSON']>;
  title?: InputMaybe<Scalars['JSON']>;
};

export type MessageInputCmp = {
  attachedFileIds: Array<Scalars['ID']>;
  content: Scalars['JSON'];
  hasNotificationsEnabled?: InputMaybe<Scalars['Boolean']>;
  hasRepliesEnabled?: InputMaybe<Scalars['Boolean']>;
  preview?: InputMaybe<Scalars['JSON']>;
  title?: InputMaybe<Scalars['JSON']>;
};

export type MessageInputLyl = {
  attachedFileIds: Array<Scalars['ID']>;
  content: Scalars['JSON'];
  hasNotificationsEnabled?: InputMaybe<Scalars['Boolean']>;
  hasRepliesEnabled?: InputMaybe<Scalars['Boolean']>;
  preview?: InputMaybe<Scalars['JSON']>;
  title?: InputMaybe<Scalars['JSON']>;
};

export type MessageInputMnc = {
  attachedFileIds?: InputMaybe<Array<Scalars['ID']>>;
  content: Scalars['JSON'];
  hasNotificationsEnabled?: InputMaybe<Scalars['Boolean']>;
  hasRepliesEnabled?: InputMaybe<Scalars['Boolean']>;
  preview?: InputMaybe<Scalars['JSON']>;
  tagIds?: InputMaybe<Array<Scalars['ID']>>;
  title?: InputMaybe<Scalars['JSON']>;
};

export type MessageInputMsg = {
  attachedFileIds?: InputMaybe<Array<Scalars['ID']>>;
  content: Scalars['JSON'];
  hasNotificationsEnabled?: InputMaybe<Scalars['Boolean']>;
  hasRepliesEnabled?: InputMaybe<Scalars['Boolean']>;
  preview?: InputMaybe<Scalars['JSON']>;
  tagIds?: InputMaybe<Array<Scalars['ID']>>;
  title?: InputMaybe<Scalars['JSON']>;
};

export type MessageInputNtf = {
  attachedFileIds: Array<Scalars['ID']>;
  content: Scalars['JSON'];
  hasNotificationsEnabled?: InputMaybe<Scalars['Boolean']>;
  hasRepliesEnabled?: InputMaybe<Scalars['Boolean']>;
  preview?: InputMaybe<Scalars['JSON']>;
  title?: InputMaybe<Scalars['JSON']>;
};

export type MessageInputNws = {
  attachedFileIds?: InputMaybe<Array<Scalars['ID']>>;
  content: Scalars['JSON'];
  hasNotificationsEnabled?: InputMaybe<Scalars['Boolean']>;
  hasRepliesEnabled?: InputMaybe<Scalars['Boolean']>;
  preview?: InputMaybe<Scalars['JSON']>;
  tagIds?: InputMaybe<Array<Scalars['ID']>>;
  title?: InputMaybe<Scalars['JSON']>;
};

export type MessageInputPev = {
  attachedFileIds?: InputMaybe<Array<Scalars['ID']>>;
  content: Scalars['JSON'];
  hasNotificationsEnabled?: InputMaybe<Scalars['Boolean']>;
  hasRepliesEnabled?: InputMaybe<Scalars['Boolean']>;
  preview?: InputMaybe<Scalars['JSON']>;
  tagIds?: InputMaybe<Array<Scalars['ID']>>;
  title?: InputMaybe<Scalars['JSON']>;
};

export type MessageInputPrp = {
  attachedFileIds: Array<Scalars['ID']>;
  content: Scalars['JSON'];
  hasNotificationsEnabled?: InputMaybe<Scalars['Boolean']>;
  hasRepliesEnabled?: InputMaybe<Scalars['Boolean']>;
  preview?: InputMaybe<Scalars['JSON']>;
  title?: InputMaybe<Scalars['JSON']>;
};

export type MessageInputSbm = {
  attachedFileIds: Array<Scalars['ID']>;
  content: Scalars['JSON'];
  hasNotificationsEnabled?: InputMaybe<Scalars['Boolean']>;
  hasRepliesEnabled?: InputMaybe<Scalars['Boolean']>;
  preview?: InputMaybe<Scalars['JSON']>;
  title?: InputMaybe<Scalars['JSON']>;
};

export type MessageInputTgs = {
  attachedFileIds?: InputMaybe<Array<Scalars['ID']>>;
  content: Scalars['JSON'];
  hasNotificationsEnabled?: InputMaybe<Scalars['Boolean']>;
  hasRepliesEnabled?: InputMaybe<Scalars['Boolean']>;
  preview?: InputMaybe<Scalars['JSON']>;
  tagIds?: InputMaybe<Array<Scalars['ID']>>;
  title?: InputMaybe<Scalars['JSON']>;
};

export type MessageInputTrs = {
  attachedFileIds?: InputMaybe<Array<Scalars['ID']>>;
  content: Scalars['JSON'];
  hasNotificationsEnabled?: InputMaybe<Scalars['Boolean']>;
  hasRepliesEnabled?: InputMaybe<Scalars['Boolean']>;
  preview?: InputMaybe<Scalars['JSON']>;
  tagIds?: InputMaybe<Array<Scalars['ID']>>;
  title?: InputMaybe<Scalars['JSON']>;
};

export type MessageInputUsr = {
  attachedFileIds: Array<Scalars['ID']>;
  content: Scalars['JSON'];
  hasNotificationsEnabled?: InputMaybe<Scalars['Boolean']>;
  hasRepliesEnabled?: InputMaybe<Scalars['Boolean']>;
  preview?: InputMaybe<Scalars['JSON']>;
  title?: InputMaybe<Scalars['JSON']>;
};

export type MessageLinkInputAnn = {
  id: Scalars['ID'];
  replyId?: InputMaybe<Scalars['ID']>;
  section: Scalars['String'];
};

export type MessageLinkInputCln = {
  id: Scalars['ID'];
  replyId?: InputMaybe<Scalars['ID']>;
  section: Scalars['String'];
};

export type MessageLinkInputCmp = {
  id: Scalars['ID'];
  replyId?: InputMaybe<Scalars['ID']>;
  section: Scalars['String'];
};

export type MessageLinkInputLyl = {
  id: Scalars['ID'];
  replyId?: InputMaybe<Scalars['ID']>;
  section: Scalars['String'];
};

export type MessageLinkInputMnc = {
  id: Scalars['ID'];
  replyId?: InputMaybe<Scalars['ID']>;
  section: Scalars['String'];
};

export type MessageLinkInputMsg = {
  id: Scalars['ID'];
  replyId?: InputMaybe<Scalars['ID']>;
  section: Scalars['String'];
};

export type MessageLinkInputNtf = {
  id: Scalars['ID'];
  replyId?: InputMaybe<Scalars['ID']>;
  section: Scalars['String'];
};

export type MessageLinkInputNws = {
  id: Scalars['ID'];
  replyId?: InputMaybe<Scalars['ID']>;
  section: Scalars['String'];
};

export type MessageLinkInputPev = {
  id: Scalars['ID'];
  replyId?: InputMaybe<Scalars['ID']>;
  section: Scalars['String'];
};

export type MessageLinkInputPrp = {
  id: Scalars['ID'];
  replyId?: InputMaybe<Scalars['ID']>;
  section: Scalars['String'];
};

export type MessageLinkInputSbm = {
  id: Scalars['ID'];
  replyId?: InputMaybe<Scalars['ID']>;
  section: Scalars['String'];
};

export type MessageLinkInputTgs = {
  id: Scalars['ID'];
  replyId?: InputMaybe<Scalars['ID']>;
  section: Scalars['String'];
};

export type MessageLinkInputTrs = {
  id: Scalars['ID'];
  replyId?: InputMaybe<Scalars['ID']>;
  section: Scalars['String'];
};

export type MessageLinkInputUsr = {
  id: Scalars['ID'];
  replyId?: InputMaybe<Scalars['ID']>;
  section: Scalars['String'];
};

export type MessageLinkOutputAnn = {
  __typename?: 'MessageLinkOutputAnn';
  id: Scalars['ID'];
  replyId?: Maybe<Scalars['ID']>;
  section: Scalars['String'];
};

export type MessageLinkOutputCln = {
  __typename?: 'MessageLinkOutputCln';
  id: Scalars['ID'];
  replyId?: Maybe<Scalars['ID']>;
  section: Scalars['String'];
};

export type MessageLinkOutputCmp = {
  __typename?: 'MessageLinkOutputCmp';
  id: Scalars['ID'];
  replyId?: Maybe<Scalars['ID']>;
  section: Scalars['String'];
};

export type MessageLinkOutputLyl = {
  __typename?: 'MessageLinkOutputLyl';
  id: Scalars['ID'];
  replyId?: Maybe<Scalars['ID']>;
  section: Scalars['String'];
};

export type MessageLinkOutputMnc = {
  __typename?: 'MessageLinkOutputMnc';
  id: Scalars['ID'];
  replyId?: Maybe<Scalars['ID']>;
  section: Scalars['String'];
};

export type MessageLinkOutputMsg = {
  __typename?: 'MessageLinkOutputMsg';
  id: Scalars['ID'];
  replyId?: Maybe<Scalars['ID']>;
  section: Scalars['String'];
};

export type MessageLinkOutputNtf = {
  __typename?: 'MessageLinkOutputNtf';
  id: Scalars['ID'];
  replyId?: Maybe<Scalars['ID']>;
  section: Scalars['String'];
};

export type MessageLinkOutputNws = {
  __typename?: 'MessageLinkOutputNws';
  id: Scalars['ID'];
  replyId?: Maybe<Scalars['ID']>;
  section: Scalars['String'];
};

export type MessageLinkOutputPev = {
  __typename?: 'MessageLinkOutputPev';
  id: Scalars['ID'];
  replyId?: Maybe<Scalars['ID']>;
  section: Scalars['String'];
};

export type MessageLinkOutputPrp = {
  __typename?: 'MessageLinkOutputPrp';
  id: Scalars['ID'];
  replyId?: Maybe<Scalars['ID']>;
  section: Scalars['String'];
};

export type MessageLinkOutputSbm = {
  __typename?: 'MessageLinkOutputSbm';
  id: Scalars['ID'];
  replyId?: Maybe<Scalars['ID']>;
  section: Scalars['String'];
};

export type MessageLinkOutputTgs = {
  __typename?: 'MessageLinkOutputTgs';
  id: Scalars['ID'];
  replyId?: Maybe<Scalars['ID']>;
  section: Scalars['String'];
};

export type MessageLinkOutputTrs = {
  __typename?: 'MessageLinkOutputTrs';
  id: Scalars['ID'];
  replyId?: Maybe<Scalars['ID']>;
  section: Scalars['String'];
};

export type MessageLinkOutputUsr = {
  __typename?: 'MessageLinkOutputUsr';
  id: Scalars['ID'];
  replyId?: Maybe<Scalars['ID']>;
  section: Scalars['String'];
};

export type MessageOutputAnn = {
  __typename?: 'MessageOutputAnn';
  attachments: Array<AttachmentOutputAnn>;
  configuration?: Maybe<Scalars['JSON']>;
  content: MultiStringAnn;
  createdAt: Scalars['DateTime'];
  /**  TODO: Eventually replace with some type containing actual user details. */
  creatorId?: Maybe<Scalars['ID']>;
  hasNotificationsEnabled: Scalars['Boolean'];
  hasRepliesEnabled: Scalars['Boolean'];
  id: Scalars['ID'];
  isEditable: Scalars['Boolean'];
  /**  Null if never edited. */
  lastEditedAt?: Maybe<Scalars['DateTime']>;
  /**  Null if never edited or if the current user is not allowed to see this information. */
  lastEditorId?: Maybe<Scalars['ID']>;
  parentId?: Maybe<Scalars['ID']>;
  preview: MultiStringAnn;
  replies: Array<MessageOutputAnn>;
  title?: Maybe<MultiStringAnn>;
};

export type MessageOutputCln = {
  __typename?: 'MessageOutputCln';
  attachments: Array<AttachmentOutputCln>;
  configuration?: Maybe<Scalars['JSON']>;
  content: MultiStringCln;
  createdAt: Scalars['DateTime'];
  /**  TODO: Eventually replace with some type containing actual user details. */
  creatorId?: Maybe<Scalars['ID']>;
  hasRepliesEnabled: Scalars['Boolean'];
  id: Scalars['ID'];
  isEditable: Scalars['Boolean'];
  /**  Null if never edited. */
  lastEditedAt?: Maybe<Scalars['DateTime']>;
  /**  Null if never edited or if the current user is not allowed to see this information. */
  lastEditorId?: Maybe<Scalars['ID']>;
  preview: MultiStringCln;
  replies: Array<MessageOutputCln>;
  title?: Maybe<MultiStringCln>;
};

export type MessageOutputCmp = {
  __typename?: 'MessageOutputCmp';
  attachments: Array<AttachmentOutputCmp>;
  configuration?: Maybe<Scalars['JSON']>;
  content: MultiStringCmp;
  createdAt: Scalars['DateTime'];
  /**  TODO: Eventually replace with some type containing actual user details. */
  creatorId?: Maybe<Scalars['ID']>;
  hasNotificationsEnabled: Scalars['Boolean'];
  hasRepliesEnabled: Scalars['Boolean'];
  id: Scalars['ID'];
  isEditable: Scalars['Boolean'];
  /**  Null if never edited. */
  lastEditedAt?: Maybe<Scalars['DateTime']>;
  /**  Null if never edited or if the current user is not allowed to see this information. */
  lastEditorId?: Maybe<Scalars['ID']>;
  parentId?: Maybe<Scalars['ID']>;
  preview: MultiStringCmp;
  replies: Array<MessageOutputCmp>;
  title?: Maybe<MultiStringCmp>;
};

export type MessageOutputLyl = {
  __typename?: 'MessageOutputLyl';
  attachments: Array<AttachmentOutputLyl>;
  configuration?: Maybe<Scalars['JSON']>;
  content: MultiStringLyl;
  createdAt: Scalars['DateTime'];
  /**  TODO: Eventually replace with some type containing actual user details. */
  creatorId?: Maybe<Scalars['ID']>;
  hasNotificationsEnabled: Scalars['Boolean'];
  hasRepliesEnabled: Scalars['Boolean'];
  id: Scalars['ID'];
  isEditable: Scalars['Boolean'];
  /**  Null if never edited. */
  lastEditedAt?: Maybe<Scalars['DateTime']>;
  /**  Null if never edited or if the current user is not allowed to see this information. */
  lastEditorId?: Maybe<Scalars['ID']>;
  parentId?: Maybe<Scalars['ID']>;
  preview: MultiStringLyl;
  replies: Array<MessageOutputLyl>;
  title?: Maybe<MultiStringLyl>;
};

export type MessageOutputMnc = {
  __typename?: 'MessageOutputMnc';
  attachments: Array<AttachmentOutputMnc>;
  configuration?: Maybe<Scalars['JSON']>;
  content: MultiStringMnc;
  createdAt: Scalars['DateTime'];
  /**  TODO: Eventually replace with some type containing actual user details. */
  creatorId?: Maybe<Scalars['ID']>;
  hasNotificationsEnabled: Scalars['Boolean'];
  hasRepliesEnabled: Scalars['Boolean'];
  id: Scalars['ID'];
  isEditable: Scalars['Boolean'];
  /**  Null if never edited. */
  lastEditedAt?: Maybe<Scalars['DateTime']>;
  /**  Null if never edited or if the current user is not allowed to see this information. */
  lastEditorId?: Maybe<Scalars['ID']>;
  parentId?: Maybe<Scalars['ID']>;
  preview: MultiStringMnc;
  primaryTag?: Maybe<TagOutputMnc>;
  replies: Array<MessageOutputMnc>;
  tags: Array<TagOutputMnc>;
  title?: Maybe<MultiStringMnc>;
};

export type MessageOutputMsg = {
  __typename?: 'MessageOutputMsg';
  attachments: Array<AttachmentOutputMsg>;
  configuration?: Maybe<Scalars['JSON']>;
  content: MultiStringMsg;
  createdAt: Scalars['DateTime'];
  /**  TODO: Eventually replace with some type containing actual user details. */
  creatorId?: Maybe<Scalars['ID']>;
  hasNotificationsEnabled: Scalars['Boolean'];
  hasRepliesEnabled: Scalars['Boolean'];
  id: Scalars['ID'];
  isEditable: Scalars['Boolean'];
  /**  Null if never edited. */
  lastEditedAt?: Maybe<Scalars['DateTime']>;
  /**  Null if never edited or if the current user is not allowed to see this information. */
  lastEditorId?: Maybe<Scalars['ID']>;
  parentId?: Maybe<Scalars['ID']>;
  preview: MultiStringMsg;
  replies: Array<MessageOutputMsg>;
  tags: Array<TagOutputMsg>;
  title?: Maybe<MultiStringMsg>;
};

export type MessageOutputNtf = {
  __typename?: 'MessageOutputNtf';
  attachments: Array<AttachmentOutputNtf>;
  configuration?: Maybe<Scalars['JSON']>;
  content: MultiStringNtf;
  createdAt: Scalars['DateTime'];
  /**  TODO: Eventually replace with some type containing actual user details. */
  creatorId?: Maybe<Scalars['ID']>;
  hasNotificationsEnabled: Scalars['Boolean'];
  hasRepliesEnabled: Scalars['Boolean'];
  id: Scalars['ID'];
  isEditable: Scalars['Boolean'];
  /**  Null if never edited. */
  lastEditedAt?: Maybe<Scalars['DateTime']>;
  /**  Null if never edited or if the current user is not allowed to see this information. */
  lastEditorId?: Maybe<Scalars['ID']>;
  parentId?: Maybe<Scalars['ID']>;
  preview: MultiStringNtf;
  replies: Array<MessageOutputNtf>;
  title?: Maybe<MultiStringNtf>;
};

export type MessageOutputNws = {
  __typename?: 'MessageOutputNws';
  attachments: Array<AttachmentOutputNws>;
  configuration?: Maybe<Scalars['JSON']>;
  content: MultiStringNws;
  createdAt: Scalars['DateTime'];
  /**  TODO: Eventually replace with some type containing actual user details. */
  creatorId?: Maybe<Scalars['ID']>;
  hasNotificationsEnabled: Scalars['Boolean'];
  hasRepliesEnabled: Scalars['Boolean'];
  id: Scalars['ID'];
  isEditable: Scalars['Boolean'];
  /**  Null if never edited. */
  lastEditedAt?: Maybe<Scalars['DateTime']>;
  /**  Null if never edited or if the current user is not allowed to see this information. */
  lastEditorId?: Maybe<Scalars['ID']>;
  parentId?: Maybe<Scalars['ID']>;
  preview: MultiStringNws;
  primaryTag?: Maybe<TagOutputNws>;
  replies: Array<MessageOutputNws>;
  tags: Array<TagOutputNws>;
  title?: Maybe<MultiStringNws>;
};

export type MessageOutputPev = {
  __typename?: 'MessageOutputPev';
  attachments: Array<AttachmentOutputPev>;
  configuration?: Maybe<Scalars['JSON']>;
  content: MultiStringPev;
  createdAt: Scalars['DateTime'];
  /**  TODO: Eventually replace with some type containing actual user details. */
  creatorId?: Maybe<Scalars['ID']>;
  hasNotificationsEnabled: Scalars['Boolean'];
  hasRepliesEnabled: Scalars['Boolean'];
  id: Scalars['ID'];
  isEditable: Scalars['Boolean'];
  /**  Null if never edited. */
  lastEditedAt?: Maybe<Scalars['DateTime']>;
  /**  Null if never edited or if the current user is not allowed to see this information. */
  lastEditorId?: Maybe<Scalars['ID']>;
  parentId?: Maybe<Scalars['ID']>;
  preview: MultiStringPev;
  primaryTag?: Maybe<TagOutputPev>;
  replies: Array<MessageOutputPev>;
  tags: Array<TagOutputPev>;
  title?: Maybe<MultiStringPev>;
};

export type MessageOutputPrp = {
  __typename?: 'MessageOutputPrp';
  attachments: Array<AttachmentOutputPrp>;
  configuration?: Maybe<Scalars['JSON']>;
  content: MultiStringPrp;
  createdAt: Scalars['DateTime'];
  /**  TODO: Eventually replace with some type containing actual user details. */
  creatorId?: Maybe<Scalars['ID']>;
  hasNotificationsEnabled: Scalars['Boolean'];
  hasRepliesEnabled: Scalars['Boolean'];
  id: Scalars['ID'];
  isEditable: Scalars['Boolean'];
  /**  Null if never edited. */
  lastEditedAt?: Maybe<Scalars['DateTime']>;
  /**  Null if never edited or if the current user is not allowed to see this information. */
  lastEditorId?: Maybe<Scalars['ID']>;
  parentId?: Maybe<Scalars['ID']>;
  preview: MultiStringPrp;
  replies: Array<MessageOutputPrp>;
  title?: Maybe<MultiStringPrp>;
};

export type MessageOutputSbm = {
  __typename?: 'MessageOutputSbm';
  attachments: Array<AttachmentOutputSbm>;
  configuration?: Maybe<Scalars['JSON']>;
  content: MultiStringSbm;
  createdAt: Scalars['DateTime'];
  /**  TODO: Eventually replace with some type containing actual user details. */
  creatorId?: Maybe<Scalars['ID']>;
  hasNotificationsEnabled: Scalars['Boolean'];
  hasRepliesEnabled: Scalars['Boolean'];
  id: Scalars['ID'];
  isEditable: Scalars['Boolean'];
  /**  Null if never edited. */
  lastEditedAt?: Maybe<Scalars['DateTime']>;
  /**  Null if never edited or if the current user is not allowed to see this information. */
  lastEditorId?: Maybe<Scalars['ID']>;
  parentId?: Maybe<Scalars['ID']>;
  preview: MultiStringSbm;
  replies: Array<MessageOutputSbm>;
  title?: Maybe<MultiStringSbm>;
};

export type MessageOutputTgs = {
  __typename?: 'MessageOutputTgs';
  attachments: Array<AttachmentOutputTgs>;
  configuration?: Maybe<Scalars['JSON']>;
  content: MultiStringTgs;
  createdAt: Scalars['DateTime'];
  /**  TODO: Eventually replace with some type containing actual user details. */
  creatorId?: Maybe<Scalars['ID']>;
  hasNotificationsEnabled: Scalars['Boolean'];
  hasRepliesEnabled: Scalars['Boolean'];
  id: Scalars['ID'];
  isEditable: Scalars['Boolean'];
  /**  Null if never edited. */
  lastEditedAt?: Maybe<Scalars['DateTime']>;
  /**  Null if never edited or if the current user is not allowed to see this information. */
  lastEditorId?: Maybe<Scalars['ID']>;
  parentId?: Maybe<Scalars['ID']>;
  preview: MultiStringTgs;
  replies: Array<MessageOutputTgs>;
  tags: Array<TagOutputTgs>;
  title?: Maybe<MultiStringTgs>;
};

export type MessageOutputTrs = {
  __typename?: 'MessageOutputTrs';
  attachments: Array<AttachmentOutputTrs>;
  configuration?: Maybe<Scalars['JSON']>;
  content: MultiStringTrs;
  createdAt: Scalars['DateTime'];
  /**  TODO: Eventually replace with some type containing actual user details. */
  creatorId?: Maybe<Scalars['ID']>;
  hasNotificationsEnabled: Scalars['Boolean'];
  hasRepliesEnabled: Scalars['Boolean'];
  id: Scalars['ID'];
  isEditable: Scalars['Boolean'];
  /**  Null if never edited. */
  lastEditedAt?: Maybe<Scalars['DateTime']>;
  /**  Null if never edited or if the current user is not allowed to see this information. */
  lastEditorId?: Maybe<Scalars['ID']>;
  parentId?: Maybe<Scalars['ID']>;
  preview: MultiStringTrs;
  primaryTag?: Maybe<TagOutputTrs>;
  replies: Array<MessageOutputTrs>;
  tags: Array<TagOutputTrs>;
  title?: Maybe<MultiStringTrs>;
};

export type MessageOutputUsr = {
  __typename?: 'MessageOutputUsr';
  attachments: Array<AttachmentOutputUsr>;
  configuration?: Maybe<Scalars['JSON']>;
  content: MultiStringUsr;
  createdAt: Scalars['DateTime'];
  /**  TODO: Eventually replace with some type containing actual user details. */
  creatorId?: Maybe<Scalars['ID']>;
  hasNotificationsEnabled: Scalars['Boolean'];
  hasRepliesEnabled: Scalars['Boolean'];
  id: Scalars['ID'];
  isEditable: Scalars['Boolean'];
  /**  Null if never edited. */
  lastEditedAt?: Maybe<Scalars['DateTime']>;
  /**  Null if never edited or if the current user is not allowed to see this information. */
  lastEditorId?: Maybe<Scalars['ID']>;
  parentId?: Maybe<Scalars['ID']>;
  preview: MultiStringUsr;
  replies: Array<MessageOutputUsr>;
  title?: Maybe<MultiStringUsr>;
};

export type MessagesMutations = {
  __typename?: 'MessagesMutations';
  addReply: MessageOutputMsg;
  deleteReply: MessageOutputMsg;
  editReply: MessageOutputMsg;
};


export type MessagesMutationsAddReplyArgs = {
  input: MessageInputMsg;
  parentId: Scalars['ID'];
};


export type MessagesMutationsDeleteReplyArgs = {
  id: Scalars['ID'];
};


export type MessagesMutationsEditReplyArgs = {
  id: Scalars['ID'];
  input: MessageInputMsg;
};

export type MessagesQueries = {
  __typename?: 'MessagesQueries';
  getReply: MessageOutputMsg;
};


export type MessagesQueriesGetReplyArgs = {
  id: Scalars['ID'];
};

export type Module = {
  __typename?: 'Module';
  endpoints: Array<HttpEndpoint>;
  name: Scalars['String'];
  registered: Scalars['Timestamp'];
  runtime: Scalars['String'];
  schema?: Maybe<Scalars['String']>;
  started: Scalars['Timestamp'];
  tail: Array<RequestNotation>;
  version?: Maybe<Scalars['String']>;
};


export type ModuleTailArgs = {
  n?: InputMaybe<Scalars['Int']>;
};

export type MultiStringAnn = {
  __typename?: 'MultiStringAnn';
  all: Scalars['JSON'];
  available: Array<Scalars['String']>;
};

export type MultiStringCln = {
  __typename?: 'MultiStringCln';
  all: Scalars['JSON'];
  available: Array<Scalars['String']>;
};

export type MultiStringCmp = {
  __typename?: 'MultiStringCmp';
  all: Scalars['JSON'];
  available: Array<Scalars['String']>;
};

export type MultiStringLyl = {
  __typename?: 'MultiStringLyl';
  all: Scalars['JSON'];
  available: Array<Scalars['String']>;
};

export type MultiStringMnc = {
  __typename?: 'MultiStringMnc';
  all: Scalars['JSON'];
  available: Array<Scalars['String']>;
};

export type MultiStringMsg = {
  __typename?: 'MultiStringMsg';
  all: Scalars['JSON'];
  available: Array<Scalars['String']>;
};

export type MultiStringNtf = {
  __typename?: 'MultiStringNtf';
  all: Scalars['JSON'];
  available: Array<Scalars['String']>;
};

export type MultiStringNws = {
  __typename?: 'MultiStringNws';
  all: Scalars['JSON'];
  available: Array<Scalars['String']>;
};

export type MultiStringPev = {
  __typename?: 'MultiStringPev';
  all: Scalars['JSON'];
  available: Array<Scalars['String']>;
};

export type MultiStringPrp = {
  __typename?: 'MultiStringPrp';
  all: Scalars['JSON'];
  available: Array<Scalars['String']>;
};

export type MultiStringSbm = {
  __typename?: 'MultiStringSbm';
  all: Scalars['JSON'];
  available: Array<Scalars['String']>;
};

export type MultiStringTgs = {
  __typename?: 'MultiStringTgs';
  all: Scalars['JSON'];
  available: Array<Scalars['String']>;
};

export type MultiStringTrs = {
  __typename?: 'MultiStringTrs';
  all: Scalars['JSON'];
  available: Array<Scalars['String']>;
};

export type MultiStringUsr = {
  __typename?: 'MultiStringUsr';
  all: Scalars['JSON'];
  available: Array<Scalars['String']>;
};

export type MunicipalityOutputAnn = {
  __typename?: 'MunicipalityOutputAnn';
  configuration?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  number: Scalars['Int'];
};

export type MunicipalityOutputCln = {
  __typename?: 'MunicipalityOutputCln';
  configuration?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  number: Scalars['Int'];
};

export type MunicipalityOutputCmp = {
  __typename?: 'MunicipalityOutputCmp';
  configuration?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  number: Scalars['Int'];
};

export type MunicipalityOutputLyl = {
  __typename?: 'MunicipalityOutputLyl';
  configuration?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  number: Scalars['Int'];
};

export type MunicipalityOutputMnc = {
  __typename?: 'MunicipalityOutputMnc';
  configuration?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  number: Scalars['Int'];
};

export type MunicipalityOutputMsg = {
  __typename?: 'MunicipalityOutputMsg';
  configuration?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  number: Scalars['Int'];
};

export type MunicipalityOutputNtf = {
  __typename?: 'MunicipalityOutputNtf';
  configuration?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  number: Scalars['Int'];
};

export type MunicipalityOutputNws = {
  __typename?: 'MunicipalityOutputNws';
  configuration?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  number: Scalars['Int'];
};

export type MunicipalityOutputPev = {
  __typename?: 'MunicipalityOutputPev';
  configuration?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  number: Scalars['Int'];
};

export type MunicipalityOutputPrp = {
  __typename?: 'MunicipalityOutputPrp';
  configuration?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  number: Scalars['Int'];
};

export type MunicipalityOutputSbm = {
  __typename?: 'MunicipalityOutputSbm';
  configuration?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  number: Scalars['Int'];
};

export type MunicipalityOutputTgs = {
  __typename?: 'MunicipalityOutputTgs';
  configuration?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  number: Scalars['Int'];
};

export type MunicipalityOutputTrs = {
  __typename?: 'MunicipalityOutputTrs';
  configuration?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  number: Scalars['Int'];
};

export type MunicipalityOutputUsr = {
  __typename?: 'MunicipalityOutputUsr';
  configuration?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  number: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  announcements: AnnouncementsMutations;
  complaints: ComplaintsMutations;
  loyalty: LoyaltyMutations;
  lvpSsoAuthenticator: LvpSsoAuthenticatorMutations;
  messages: MessagesMutations;
  news: NewsMutations;
  notifications: NotificationsMutations;
  phoneNumberAuthenticator: PhoneNumberAuthenticatorMutations;
  policeReports: PoliceReportsMutations;
  publicEvents: PublicEventsMutations;
  submissions: SubmissionsMutations;
  tourism: TourismMutations;
  usernameAuthenticator: UsernameAuthenticatorMutations;
};

/**  See respective statuses for explanation. */
export enum NewsActionNws {
  Draft = 'DRAFT',
  Publish = 'PUBLISH',
  Schedule = 'SCHEDULE'
}

export type NewsInputNws = {
  message: MessageInputNws;
  publishState: PublishStateInputNws;
};

/**  TODO: Re-authorize, when authentication context is populated with administration details. */
export type NewsMutations = {
  __typename?: 'NewsMutations';
  addNews: NewsOutputNws;
  deleteNews: Scalars['ID'];
  editPublishedNewsContent: NewsOutputNws;
  editUnpublishedNews: NewsOutputNws;
  republishNews: NewsOutputNws;
  retractNews: NewsOutputNws;
  setPublishState: NewsOutputNws;
};


/**  TODO: Re-authorize, when authentication context is populated with administration details. */
export type NewsMutationsAddNewsArgs = {
  input: NewsInputNws;
};


/**  TODO: Re-authorize, when authentication context is populated with administration details. */
export type NewsMutationsDeleteNewsArgs = {
  id: Scalars['ID'];
};


/**  TODO: Re-authorize, when authentication context is populated with administration details. */
export type NewsMutationsEditPublishedNewsContentArgs = {
  id: Scalars['ID'];
  messageInput: MessageInputNws;
};


/**  TODO: Re-authorize, when authentication context is populated with administration details. */
export type NewsMutationsEditUnpublishedNewsArgs = {
  id: Scalars['ID'];
  input: NewsInputNws;
};


/**  TODO: Re-authorize, when authentication context is populated with administration details. */
export type NewsMutationsRepublishNewsArgs = {
  id: Scalars['ID'];
};


/**  TODO: Re-authorize, when authentication context is populated with administration details. */
export type NewsMutationsRetractNewsArgs = {
  id: Scalars['ID'];
};


/**  TODO: Re-authorize, when authentication context is populated with administration details. */
export type NewsMutationsSetPublishStateArgs = {
  id: Scalars['ID'];
  input: PublishStateInputNws;
};

export type NewsOutputNws = {
  __typename?: 'NewsOutputNws';
  configuration?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  message: MessageOutputNws;
  publishedAt?: Maybe<Scalars['DateTime']>;
  retractedAt?: Maybe<Scalars['DateTime']>;
  status: NewsStatusNws;
};

export enum NewsStatusNws {
  /**  Generally not visible to end user. */
  Deleted = 'DELETED',
  /**  The news item has no publish timestamp set. */
  Drafted = 'DRAFTED',
  /**  The news item has expired due to retention policy. Not yet possible. */
  Expired = 'EXPIRED',
  /**  The news item has been created automatically from an external source and is pending approval. Not yet possible. */
  Imported = 'IMPORTED',
  /**  The news item has a publish timestamp set in the past (or present). */
  Published = 'PUBLISHED',
  /**  The news item has been manually retracted. It may yet be re-published. */
  Retracted = 'RETRACTED',
  /**  The news item has a publish timestamp set in the future. */
  Scheduled = 'SCHEDULED'
}

export type NotificationInputNtf = {
  body?: InputMaybe<Scalars['JSON']>;
  clientId: Scalars['ID'];
  collapseTag?: InputMaybe<Scalars['String']>;
  deliveryPriority: DeliveryPriorityNtf;
  eventOccurredAt?: InputMaybe<Scalars['DateTime']>;
  link?: InputMaybe<MessageLinkInputNtf>;
  title?: InputMaybe<Scalars['JSON']>;
  undeliveredExpiresAt?: InputMaybe<Scalars['DateTime']>;
  userIds: Array<Scalars['ID']>;
};

export type NotificationOutputNtf = {
  __typename?: 'NotificationOutputNtf';
  body?: Maybe<MultiStringNtf>;
  clientId: Scalars['ID'];
  /**
   *  If collapseTag is specified and the user has an older notification
   *  with the same collapseTag, the newer notification will replace it.
   *  Corresponds to AndroidNotification tag and to apns-collapse-id.
   *  APNs limits the length of a collapseTag to 64 bytes.
   */
  collapseTag?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deliveryPriority: DeliveryPriorityNtf;
  /**
   *  The time that the event in the notification occurred.
   *  On Android, notifications are sorted by this time.
   */
  eventOccurredAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  link?: Maybe<MessageLinkOutputNtf>;
  title?: Maybe<MultiStringNtf>;
  /**
   *  No later than 28 days in the future (due to Android limit).
   *  If unset, defaults to the maximum value. If set to a date
   *  in the past, a single delivery attempt will be made. A safe
   *  value to trigger this intentionally is 1970-01-01T00:00Z.
   */
  undeliveredExpiresAt: Scalars['DateTime'];
  userIds: Array<Scalars['ID']>;
};

export type NotificationsMutations = {
  __typename?: 'NotificationsMutations';
  /**
   *  Private API.
   *  Attempts to cancel not-yet-pushed notifications for the given users or all target users.
   *  In most cases, probably does nothing.
   */
  cancel: NotificationOutputNtf;
  registerRecipient: Scalars['Void'];
  removeRecipient: Scalars['Void'];
  /**  Private API for testing purposes. */
  send: NotificationOutputNtf;
  /**
   *  Private API.
   *  Notify the module that a new notification has been created and is ready to be sent out.
   */
  wake: Scalars['Void'];
};


export type NotificationsMutationsCancelArgs = {
  notificationId: Scalars['ID'];
  userIds?: InputMaybe<Array<Scalars['ID']>>;
};


export type NotificationsMutationsRegisterRecipientArgs = {
  input: RecipientInputNtf;
};


export type NotificationsMutationsRemoveRecipientArgs = {
  token: Scalars['String'];
};


export type NotificationsMutationsSendArgs = {
  input: NotificationInputNtf;
};

export type NotificationsQueries = {
  __typename?: 'NotificationsQueries';
  helloWorld: Scalars['String'];
};

export type OfferInputLyl = {
  endsAt: Scalars['DateTime'];
  groupIds: Array<Scalars['ID']>;
  message: MessageInputLyl;
  municipalityIds: Array<Scalars['ID']>;
  startsAt: Scalars['DateTime'];
};

export type OfferOutputLyl = {
  __typename?: 'OfferOutputLyl';
  endsAt: Scalars['DateTime'];
  groups: Array<GroupOutputLyl>;
  id: Scalars['ID'];
  isUserQualified?: Maybe<Scalars['Boolean']>;
  merchant: MerchantOutputLyl;
  message: MessageOutputLyl;
  municipalityApproval: Array<ApprovalOutputLyl>;
  startsAt: Scalars['DateTime'];
  submittedAt?: Maybe<Scalars['DateTime']>;
};

export type OneOffEventPev = {
  __typename?: 'OneOffEventPev';
  startsAt: Scalars['DateTime'];
};

export type OwnLocalContextOutput = {
  __typename?: 'OwnLocalContextOutput';
  /**
   *  Despite possibly having multiple sessions, all of them have the same client, since authentication context is
   *  local.
   */
  client: AuthenticationClientOutput;
  configuration?: Maybe<Scalars['JSON']>;
  /**
   *  Optional only for scenarios when not used as authentication context of a currently active user, but rather when
   *  some *not* user driven process (i.e., a cronjob) is querying details of some users. Otherwise guaranteed to be
   *  not null.
   */
  currentSession?: Maybe<SessionOutput>;
  /**  Other sessions excluding current session & strong authentication session. */
  otherSessions: Array<SessionOutput>;
  parameters: UserParametersOutput;
  /**  Present if user has a valid session with a recognized strong authentication provider. */
  strongAuthenticationSession?: Maybe<SessionOutput>;
  userId: Scalars['ID'];
};

export type PhoneNumberAuthenticatorMutations = {
  __typename?: 'PhoneNumberAuthenticatorMutations';
  authenticate: AuthenticateOutputPna;
  generateCode: GenerateCodeOutputPna;
};


export type PhoneNumberAuthenticatorMutationsAuthenticateArgs = {
  clientId: Scalars['String'];
  input: AuthenticateInputPna;
};


export type PhoneNumberAuthenticatorMutationsGenerateCodeArgs = {
  input: GenerateCodeInputPna;
};

export type PoliceReportInputPrp = {
  address?: InputMaybe<Scalars['String']>;
  contactInfo?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  message: MessageInputPrp;
};

export type PoliceReportOutputPrp = {
  __typename?: 'PoliceReportOutputPrp';
  address?: Maybe<Scalars['String']>;
  contactInfo?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  location?: Maybe<Scalars['String']>;
  message: MessageOutputPrp;
};

export type PoliceReportsMutations = {
  __typename?: 'PoliceReportsMutations';
  addPoliceReport: PoliceReportOutputPrp;
};


export type PoliceReportsMutationsAddPoliceReportArgs = {
  input: PoliceReportInputPrp;
};

export type PoliceReportsQueries = {
  __typename?: 'PoliceReportsQueries';
  getPoliceReport: PoliceReportOutputPrp;
  getPoliceReports: Array<PoliceReportOutputPrp>;
  helloWorld: Scalars['String'];
};


export type PoliceReportsQueriesGetPoliceReportArgs = {
  id: Scalars['ID'];
};

export enum PublicEventAdmissionPev {
  /**  Admission is free for everybody */
  Free = 'FREE',
  /**  Event is paid */
  Paid = 'PAID',
  /**  Admission is free for residents of the municipality */
  ResidentsFree = 'RESIDENTS_FREE'
}

export type PublicEventInputPev = {
  admission?: InputMaybe<PublicEventAdmissionPev>;
  duration: Scalars['Duration'];
  intentTrackingEnabled?: InputMaybe<Scalars['Boolean']>;
  location: LocationInputPev;
  message: MessageInputPev;
  pricing?: InputMaybe<Scalars['JSON']>;
  publishState?: InputMaybe<PublishStateInputPev>;
  schedule: PublicEventScheduleInputPev;
  ticketAcquireLinks?: InputMaybe<Array<Scalars['String']>>;
};

export enum PublicEventIntentPev {
  Going = 'GOING',
  Maybe = 'MAYBE',
  NotGoing = 'NOT_GOING'
}

export type PublicEventIntentRegistrationPev = {
  __typename?: 'PublicEventIntentRegistrationPev';
  intent: PublicEventIntentPev;
  registeredAt: Scalars['DateTime'];
  userId: Scalars['ID'];
};

export type PublicEventOutputPev = {
  __typename?: 'PublicEventOutputPev';
  admission?: Maybe<PublicEventAdmissionPev>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  configuration?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  duration: Scalars['String'];
  going?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  intentTrackingEnabled: Scalars['Boolean'];
  intents?: Maybe<Array<PublicEventIntentRegistrationPev>>;
  location: LocationPev;
  maybe?: Maybe<Scalars['Int']>;
  message: MessageOutputPev;
  municipality?: Maybe<MunicipalityOutputMnc>;
  notGoing?: Maybe<Scalars['Int']>;
  /**
   *  Tenant (municipality or merchant) ID.
   *  To be detailed when appropriate GraphQL types/queries are introduced
   */
  organizerId: Scalars['ID'];
  pricing?: Maybe<MultiStringPev>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  schedule: PublicEventSchedulePev;
  ticketAcquireLinks?: Maybe<Array<Scalars['String']>>;
};

export type PublicEventScheduleInputPev = {
  quartzCronSchedule?: InputMaybe<Scalars['String']>;
  startsAt: Scalars['DateTime'];
};

export type PublicEventSchedulePev = OneOffEventPev | RecurringEventPev;

export type PublicEventsMutations = {
  __typename?: 'PublicEventsMutations';
  /**
   *  For merchants, municipalityId is required.
   *  For local admins, municipalityId must be omitted or equal to tenantId of caller's authentication context.
   */
  addPublicEvent: PublicEventOutputPev;
  approveExternalPublicEvent: PublicEventOutputPev;
  cancelPublicEvent: PublicEventOutputPev;
  deleteAttendanceIntent: Scalars['ID'];
  deletePublicEvent: Scalars['ID'];
  editPublicEventContent: PublicEventOutputPev;
  setAttendanceIntent: PublicEventIntentRegistrationPev;
};


export type PublicEventsMutationsAddPublicEventArgs = {
  input: PublicEventInputPev;
  municipalityId?: InputMaybe<Scalars['ID']>;
};


export type PublicEventsMutationsApproveExternalPublicEventArgs = {
  id: Scalars['ID'];
  publishState: PublishStateInputPev;
};


export type PublicEventsMutationsCancelPublicEventArgs = {
  id: Scalars['ID'];
};


export type PublicEventsMutationsDeleteAttendanceIntentArgs = {
  publicEventId: Scalars['ID'];
};


export type PublicEventsMutationsDeletePublicEventArgs = {
  id: Scalars['ID'];
};


export type PublicEventsMutationsEditPublicEventContentArgs = {
  id: Scalars['ID'];
  input: PublicEventInputPev;
};


export type PublicEventsMutationsSetAttendanceIntentArgs = {
  intent: PublicEventIntentPev;
  publicEventId: Scalars['ID'];
};

export type PublicEventsQueries = {
  __typename?: 'PublicEventsQueries';
  getPublicEvent: PublicEventOutputPev;
  getPublicEvents: Array<PublicEventOutputPev>;
  helloWorld: Scalars['String'];
  /**
   *  For merchants, municipalityId is required.
   *  For local admins, municipalityId must be omitted or equal to tenantId of caller's authentication context.
   */
  tags: Array<TagOutputPev>;
};


export type PublicEventsQueriesGetPublicEventArgs = {
  id: Scalars['ID'];
};


export type PublicEventsQueriesGetPublicEventsArgs = {
  withTagIds?: InputMaybe<Array<Scalars['ID']>>;
};


export type PublicEventsQueriesTagsArgs = {
  municipalityId?: InputMaybe<Scalars['ID']>;
};

export type PublicKeyOutput = {
  __typename?: 'PublicKeyOutput';
  /**  Base64 of ASN.1 DER of PKCS #8 of Ed25519 */
  key: Scalars['String'];
  shortId: Scalars['Int'];
};

export enum PublishActionPev {
  Publish = 'PUBLISH',
  Schedule = 'SCHEDULE'
}

export type PublishStateInputNws = {
  action: NewsActionNws;
  scheduledAt?: InputMaybe<Scalars['DateTime']>;
};

export type PublishStateInputPev = {
  action: PublishActionPev;
  scheduledAt?: InputMaybe<Scalars['DateTime']>;
};

export type QrDataInputLyl = {
  applicationId: Scalars['Int'];
  batchNumber: Scalars['Int'];
  expiresAt: Scalars['Long'];
  groupIds: Array<Scalars['Int']>;
  issuedAt: Scalars['Long'];
  issuerId: Scalars['Int'];
  municipalityShortId: Scalars['Int'];
  schemaPrefix: Scalars['String'];
  signatureBase64: Scalars['String'];
  userId: Scalars['ID'];
};

export type QrDataLyl = {
  __typename?: 'QrDataLyl';
  applicationId: Scalars['Int'];
  batchNumber: Scalars['Int'];
  expiresAt: Scalars['Long'];
  formattedData: Scalars['String'];
  groupIds: Array<Scalars['Int']>;
  issuedAt: Scalars['Long'];
  issuerId: Scalars['Int'];
  municipalityShortId: Scalars['Int'];
  schemaPrefix: Scalars['String'];
  signatureBase64: Scalars['String'];
  userId: Scalars['ID'];
};

export type Query = {
  __typename?: 'Query';
  announcements: AnnouncementsQueries;
  clients: ClientsQueries;
  complaints: ComplaintsQueries;
  crypto: CryptoQueries;
  igpAuthenticator: IgpAuthenticatorQueries;
  loyalty: LoyaltyQueries;
  lvpSsoAuthenticator: LvpSsoAuthenticatorQueries;
  messages: MessagesQueries;
  municipalities: MunicipalitiesQueries;
  news: NewsQueries;
  notifications: NotificationsQueries;
  phoneNumberAuthenticator: PhoneNumberAuthenticatorQueries;
  policeReports: PoliceReportsQueries;
  publicEvents: PublicEventsQueries;
  submissions: SubmissionsQueries;
  system?: Maybe<SystemQuery>;
  tags: TagsQueries;
  tourism: TourismQueries;
  usernameAuthenticator: UsernameAuthenticatorQueries;
  users: UsersQueries;
};

export type RecipientInputNtf = {
  appPlatform: Scalars['String'];
  appVersion: Scalars['String'];
  languageCode: Scalars['String'];
  token: Scalars['String'];
};

export type RecurringEventPev = {
  __typename?: 'RecurringEventPev';
  firstOccursAt: Scalars['DateTime'];
  quartzCronSchedule: Scalars['String'];
};

export type RequestNotation = {
  __typename?: 'RequestNotation';
  args: Scalars['JsonObject'];
  context: Scalars['JsonObject'];
  /** Milliseconds */
  executionTime: Scalars['Int'];
  product: Scalars['JsonObject'];
  query: Scalars['String'];
  time: Scalars['Timestamp'];
};

export type SessionOutput = {
  __typename?: 'SessionOutput';
  authenticationId: Scalars['String'];
  authenticationMethod: AuthenticationMethodUsr;
  configuration?: Maybe<Scalars['JSON']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  ip?: Maybe<Scalars['String']>;
  isTransient: Scalars['Boolean'];
  lastAuthenticationAt: Scalars['DateTime'];
  token: Scalars['ID'];
  tokenLastUsedAt: Scalars['DateTime'];
};

export type SubmissionInputSbm = {
  message: MessageInputSbm;
  submissionType: SubmissionTypeSbm;
};

export type SubmissionOutputSbm = {
  __typename?: 'SubmissionOutputSbm';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  message: MessageOutputSbm;
  registrationDate?: Maybe<Scalars['DateTime']>;
  registrationNr?: Maybe<Scalars['String']>;
  submissionType: SubmissionTypeSbm;
};

export enum SubmissionTypeSbm {
  CofinancingPrivateElementarySchool = 'COFINANCING_PRIVATE_ELEMENTARY_SCHOOL',
  CofinancingPrivateKindergarten = 'COFINANCING_PRIVATE_KINDERGARTEN',
  ConstructionBoard = 'CONSTRUCTION_BOARD',
  General = 'GENERAL',
  RealEstateTaxRelief = 'REAL_ESTATE_TAX_RELIEF'
}

export type SubmissionsMutations = {
  __typename?: 'SubmissionsMutations';
  addSubmission: SubmissionOutputSbm;
  deleteSubmission: Scalars['ID'];
};


export type SubmissionsMutationsAddSubmissionArgs = {
  input: SubmissionInputSbm;
};


export type SubmissionsMutationsDeleteSubmissionArgs = {
  id: Scalars['ID'];
};

export type SystemQuery = {
  __typename?: 'SystemQuery';
  debug?: Maybe<Scalars['Boolean']>;
  heap: Scalars['String'];
  modules: Array<Module>;
  requests10m: Scalars['Int'];
  started: Scalars['Timestamp'];
  validateAuthExpression: Scalars['Boolean'];
  version: Scalars['String'];
};


export type SystemQueryValidateAuthExpressionArgs = {
  expression: Scalars['String'];
};

export type TagOutputMnc = {
  __typename?: 'TagOutputMnc';
  createdAt: Scalars['DateTime'];
  groupKey: Scalars['String'];
  iconFileId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  title: MultiStringMnc;
};

export type TagOutputMsg = {
  __typename?: 'TagOutputMsg';
  createdAt: Scalars['DateTime'];
  groupKey: Scalars['String'];
  iconFileId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  title: MultiStringMsg;
};

export type TagOutputNws = {
  __typename?: 'TagOutputNws';
  createdAt: Scalars['DateTime'];
  groupKey: Scalars['String'];
  iconFileId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  title: MultiStringNws;
};

export type TagOutputPev = {
  __typename?: 'TagOutputPev';
  createdAt: Scalars['DateTime'];
  groupKey: Scalars['String'];
  iconFileId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  title: MultiStringPev;
};

export type TagOutputTgs = {
  __typename?: 'TagOutputTgs';
  createdAt: Scalars['DateTime'];
  groupKey: Scalars['String'];
  id: Scalars['ID'];
  title: MultiStringTgs;
};

export type TagOutputTrs = {
  __typename?: 'TagOutputTrs';
  createdAt: Scalars['DateTime'];
  groupKey: Scalars['String'];
  iconFileId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  title: MultiStringTrs;
};

export type TaggedSignature = {
  __typename?: 'TaggedSignature';
  dataWithKeyShortId: Scalars['String'];
  keyShortId: Scalars['Int'];
  signature: Scalars['String'];
};

export type TagsQueries = {
  __typename?: 'TagsQueries';
  helloWorld: Scalars['String'];
};

export type TargetInputAnn = {
  id: Scalars['ID'];
  kind: TargetKindAnn;
};

export enum TargetKindAnn {
  User = 'USER'
}

/**
 * ##
 *  Invoice inputs
 * ##
 */
export type TextInput = {
  lang: Scalars['String'];
  text: Scalars['String'];
};

export type TextOutput = {
  __typename?: 'TextOutput';
  isDeleted?: Maybe<Scalars['Boolean']>;
  lang: Scalars['String'];
  text: Scalars['String'];
};

export type TourismMutations = {
  __typename?: 'TourismMutations';
  addDestination: DestinationOutputTrs;
  deleteDestination: Scalars['ID'];
  editDestination: DestinationOutputTrs;
};


export type TourismMutationsAddDestinationArgs = {
  input: DestinationInputTrs;
};


export type TourismMutationsDeleteDestinationArgs = {
  id: Scalars['ID'];
};


export type TourismMutationsEditDestinationArgs = {
  id: Scalars['ID'];
  input: DestinationInputTrs;
};

export type TourismQueries = {
  __typename?: 'TourismQueries';
  getDestination: DestinationOutputTrs;
  /**
   *  enabledOnly – Exclude disabled destinations from results. NOTE: Is only meaningful for administrators. For residents
   *  exclusion of disabled destinations will be forced.
   *
   */
  getDestinations: Array<DestinationOutputTrs>;
  getTags: Array<TagOutputTrs>;
  helloWorld: Scalars['String'];
};


export type TourismQueriesGetDestinationArgs = {
  id: Scalars['ID'];
};


export type TourismQueriesGetDestinationsArgs = {
  enabledOnly?: InputMaybe<Scalars['Boolean']>;
  withTagIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type UserParameterOutput = {
  __typename?: 'UserParameterOutput';
  /**  Null if self proclaimed. */
  authority?: Maybe<AuthenticationMethodUsr>;
  isEditable: Scalars['Boolean'];
  key: Scalars['String'];
  setAt: Scalars['DateTime'];
  value: Scalars['String'];
};

export type UserParametersOutput = {
  __typename?: 'UserParametersOutput';
  allParameters: Array<UserParameterOutput>;
  commonParameters: CommonUserParametersOutput;
};

export type UsersQueries = {
  __typename?: 'UsersQueries';
  ownAuthenticationContext?: Maybe<OwnLocalContextOutput>;
};

export type UtilizedOfferOutputLyl = {
  __typename?: 'UtilizedOfferOutputLyl';
  lastUtilizedAt?: Maybe<Scalars['DateTime']>;
  offer: OfferOutputLyl;
};

export type ClientsQueries = {
  __typename?: 'clientsQueries';
  getByMunicipality: ClientOutputCln;
  helloWorld: Scalars['String'];
};


export type ClientsQueriesGetByMunicipalityArgs = {
  municipalityId: Scalars['ID'];
  platform: ClientPlatformCln;
};

export type ComplaintsQueries = {
  __typename?: 'complaintsQueries';
  getComplaint: ComplaintOutputCmp;
  getComplaints: Array<ComplaintOutputCmp>;
  helloWorld: Scalars['String'];
};


export type ComplaintsQueriesGetComplaintArgs = {
  id: Scalars['ID'];
};

export type LoyaltyQueries = {
  __typename?: 'loyaltyQueries';
  getLocalGroups: Array<GroupOutputLyl>;
  getMunicipalityGroups: Array<GroupOutputLyl>;
  /**  Eventually offer retrieval queries should be allowed for all client platforms. First though let us support merchants & administrators. */
  getOffer: OfferOutputLyl;
  getOffers: Array<OfferOutputLyl>;
  getQrData: QrDataLyl;
  helloWorld: Scalars['String'];
  scanQr: Array<UtilizedOfferOutputLyl>;
};


export type LoyaltyQueriesGetMunicipalityGroupsArgs = {
  municipalityId: Scalars['ID'];
};


export type LoyaltyQueriesGetOfferArgs = {
  id: Scalars['ID'];
};


export type LoyaltyQueriesGetOffersArgs = {
  qualifiedOnly?: InputMaybe<Scalars['Boolean']>;
};


export type LoyaltyQueriesScanQrArgs = {
  input: QrDataInputLyl;
};

export type MunicipalitiesQueries = {
  __typename?: 'municipalitiesQueries';
  getMunicipalities: Array<MunicipalityOutputMnc>;
  getMunicipality: MunicipalityOutputMnc;
  getMunicipalityByClientId: MunicipalityOutputMnc;
  getMunicipalityByShortId: MunicipalityOutputMnc;
  helloWorld: Scalars['String'];
};


export type MunicipalitiesQueriesGetMunicipalityArgs = {
  id: Scalars['ID'];
};


export type MunicipalitiesQueriesGetMunicipalityByClientIdArgs = {
  clientId: Scalars['ID'];
};


export type MunicipalitiesQueriesGetMunicipalityByShortIdArgs = {
  shortId: Scalars['Int'];
};

/**  TODO: Re-authorize, when authentication context is populated with administration details. */
export type NewsQueries = {
  __typename?: 'newsQueries';
  getAllNewsItems: Array<NewsOutputNws>;
  getAnyNewsItem: NewsOutputNws;
  getPublishedNewsItem: NewsOutputNws;
  getPublishedNewsItems: Array<NewsOutputNws>;
  helloWorld: Scalars['String'];
  tags: Array<TagOutputNws>;
};


/**  TODO: Re-authorize, when authentication context is populated with administration details. */
export type NewsQueriesGetAllNewsItemsArgs = {
  withTagIds?: InputMaybe<Array<Scalars['ID']>>;
};


/**  TODO: Re-authorize, when authentication context is populated with administration details. */
export type NewsQueriesGetAnyNewsItemArgs = {
  id: Scalars['ID'];
};


/**  TODO: Re-authorize, when authentication context is populated with administration details. */
export type NewsQueriesGetPublishedNewsItemArgs = {
  id: Scalars['ID'];
};


/**  TODO: Re-authorize, when authentication context is populated with administration details. */
export type NewsQueriesGetPublishedNewsItemsArgs = {
  withTagIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type PhoneNumberAuthenticatorQueries = {
  __typename?: 'phoneNumberAuthenticatorQueries';
  helloWorld: Scalars['String'];
};

export type SubmissionsQueries = {
  __typename?: 'submissionsQueries';
  getSubmission: SubmissionOutputSbm;
  getSubmissions: Array<SubmissionOutputSbm>;
  helloWorld: Scalars['String'];
};


export type SubmissionsQueriesGetSubmissionArgs = {
  id: Scalars['ID'];
};

export type UsernameAuthenticatorMutations = {
  __typename?: 'usernameAuthenticatorMutations';
  addCredentials: AddCredentialsOutputUna;
  /**  Error code 1104 - replace password. */
  authenticate: AuthenticateOutputUna;
};


export type UsernameAuthenticatorMutationsAddCredentialsArgs = {
  input: CredentialsInputUna;
};


export type UsernameAuthenticatorMutationsAuthenticateArgs = {
  input: CredentialsInputUna;
};

export type UsernameAuthenticatorQueries = {
  __typename?: 'usernameAuthenticatorQueries';
  helloWorld: Scalars['String'];
};

export type AuthenticateMutationVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
}>;


export type AuthenticateMutation = { __typename?: 'Mutation', usernameAuthenticator: { __typename?: 'usernameAuthenticatorMutations', authenticate: { __typename?: 'AuthenticateOutputUna', token?: string | null } } };

export type AddCredentialsMutationVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
}>;


export type AddCredentialsMutation = { __typename?: 'Mutation', usernameAuthenticator: { __typename?: 'usernameAuthenticatorMutations', addCredentials: { __typename?: 'AddCredentialsOutputUna', id: string, expiresAt?: any | null } } };

export type AddCommentMutationVariables = Exact<{
  parentId: Scalars['ID'];
  input: MessageInputMsg;
}>;


export type AddCommentMutation = { __typename?: 'Mutation', messages: { __typename?: 'MessagesMutations', addReply: { __typename?: 'MessageOutputMsg', id: string, createdAt: any, content: { __typename?: 'MultiStringMsg', all: any } } } };

export type AddEventMutationVariables = Exact<{
  municipalityId?: InputMaybe<Scalars['ID']>;
  input: PublicEventInputPev;
}>;


export type AddEventMutation = { __typename?: 'Mutation', publicEvents: { __typename?: 'PublicEventsMutations', addPublicEvent: { __typename?: 'PublicEventOutputPev', id: string } } };

export type EditEventMutationVariables = Exact<{
  id: Scalars['ID'];
  input: PublicEventInputPev;
}>;


export type EditEventMutation = { __typename?: 'Mutation', publicEvents: { __typename?: 'PublicEventsMutations', editPublicEventContent: { __typename?: 'PublicEventOutputPev', id: string } } };

export type GetEventsListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEventsListQuery = { __typename?: 'Query', publicEvents: { __typename?: 'PublicEventsQueries', getPublicEvents: Array<{ __typename?: 'PublicEventOutputPev', id: string, createdAt: any, publishedAt?: any | null, duration: string, schedule: { __typename?: 'OneOffEventPev', startsAt: any } | { __typename?: 'RecurringEventPev' }, location: { __typename?: 'AddressLocationPev', address: string } | { __typename?: 'CoordinatesLocationPev', address?: string | null }, message: { __typename?: 'MessageOutputPev', title?: { __typename?: 'MultiStringPev', all: any } | null, preview: { __typename?: 'MultiStringPev', all: any } } }> } };

export type GetEventQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetEventQuery = { __typename?: 'Query', publicEvents: { __typename?: 'PublicEventsQueries', getPublicEvent: { __typename?: 'PublicEventOutputPev', id: string, publishedAt?: any | null, canceledAt?: any | null, going?: number | null, notGoing?: number | null, maybe?: number | null, duration: string, schedule: { __typename?: 'OneOffEventPev', startsAt: any } | { __typename?: 'RecurringEventPev' }, location: { __typename?: 'AddressLocationPev', address: string } | { __typename?: 'CoordinatesLocationPev', address?: string | null }, municipality?: { __typename?: 'MunicipalityOutputMnc', id: string } | null, message: { __typename?: 'MessageOutputPev', id: string, hasRepliesEnabled: boolean, title?: { __typename?: 'MultiStringPev', all: any } | null, content: { __typename?: 'MultiStringPev', all: any }, attachments: Array<{ __typename?: 'AttachmentOutputPev', fileId: string, name: string }>, replies: Array<{ __typename?: 'MessageOutputPev', id: string, createdAt: any, content: { __typename?: 'MultiStringPev', all: any }, replies: Array<{ __typename?: 'MessageOutputPev', id: string, createdAt: any, content: { __typename?: 'MultiStringPev', all: any } }> }>, tags: Array<{ __typename?: 'TagOutputPev', id: string, groupKey: string, title: { __typename?: 'MultiStringPev', all: any } }> } } } };

export type DeleteEventMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteEventMutation = { __typename?: 'Mutation', publicEvents: { __typename?: 'PublicEventsMutations', deletePublicEvent: string } };

export type CancelEventMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CancelEventMutation = { __typename?: 'Mutation', publicEvents: { __typename?: 'PublicEventsMutations', cancelPublicEvent: { __typename?: 'PublicEventOutputPev', id: string } } };

export type GetEventTagsQueryVariables = Exact<{
  municipalityId?: InputMaybe<Scalars['ID']>;
}>;


export type GetEventTagsQuery = { __typename?: 'Query', publicEvents: { __typename?: 'PublicEventsQueries', tags: Array<{ __typename?: 'TagOutputPev', id: string, groupKey: string, title: { __typename?: 'MultiStringPev', all: any } }> } };

export type AddOfferMutationVariables = Exact<{
  startsAt: Scalars['DateTime'];
  endsAt: Scalars['DateTime'];
  message: MessageInputLyl;
  municipalityIds: Array<Scalars['ID']> | Scalars['ID'];
  groupIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type AddOfferMutation = { __typename?: 'Mutation', loyalty: { __typename?: 'LoyaltyMutations', addOffer: { __typename?: 'OfferOutputLyl', id: string } } };

export type EditOfferMutationVariables = Exact<{
  id: Scalars['ID'];
  startsAt: Scalars['DateTime'];
  endsAt: Scalars['DateTime'];
  message: MessageInputLyl;
  municipalityIds: Array<Scalars['ID']> | Scalars['ID'];
  groupIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type EditOfferMutation = { __typename?: 'Mutation', loyalty: { __typename?: 'LoyaltyMutations', editOffer: { __typename?: 'OfferOutputLyl', id: string } } };

export type DeleteOfferMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteOfferMutation = { __typename?: 'Mutation', loyalty: { __typename?: 'LoyaltyMutations', deleteOffer: string } };

export type GetMunicipalityGroupsQueryVariables = Exact<{
  municipalityId: Scalars['ID'];
}>;


export type GetMunicipalityGroupsQuery = { __typename?: 'Query', loyalty: { __typename?: 'loyaltyQueries', getMunicipalityGroups: Array<{ __typename?: 'GroupOutputLyl', id: string, createdAt: any, shortId: number, name: string }> } };

export type GetOfferQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetOfferQuery = { __typename?: 'Query', loyalty: { __typename?: 'loyaltyQueries', getOffer: { __typename?: 'OfferOutputLyl', id: string, submittedAt?: any | null, startsAt: any, endsAt: any, merchant: { __typename?: 'MerchantOutputLyl', id: string, name: string, registrationNumber: string, createdAt: any, legalAddress?: string | null, configuration?: any | null, description?: { __typename?: 'MultiStringLyl', all: any } | null }, message: { __typename?: 'MessageOutputLyl', id: string, hasRepliesEnabled: boolean, title?: { __typename?: 'MultiStringLyl', all: any } | null, content: { __typename?: 'MultiStringLyl', all: any }, attachments: Array<{ __typename?: 'AttachmentOutputLyl', fileId: string, name: string }>, replies: Array<{ __typename?: 'MessageOutputLyl', id: string, createdAt: any, content: { __typename?: 'MultiStringLyl', all: any }, replies: Array<{ __typename?: 'MessageOutputLyl', id: string, createdAt: any, content: { __typename?: 'MultiStringLyl', all: any } }> }> }, municipalityApproval: Array<{ __typename?: 'ApprovalOutputLyl', municipalityId: string, approvedAt?: any | null }>, groups: Array<{ __typename?: 'GroupOutputLyl', id: string, createdAt: any, shortId: number, name: string }> } } };

export type GetOffersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOffersQuery = { __typename?: 'Query', loyalty: { __typename?: 'loyaltyQueries', getOffers: Array<{ __typename?: 'OfferOutputLyl', id: string, submittedAt?: any | null, startsAt: any, endsAt: any, merchant: { __typename?: 'MerchantOutputLyl', id: string, name: string, registrationNumber: string, createdAt: any, legalAddress?: string | null, configuration?: any | null, description?: { __typename?: 'MultiStringLyl', all: any } | null }, message: { __typename?: 'MessageOutputLyl', title?: { __typename?: 'MultiStringLyl', all: any } | null, preview: { __typename?: 'MultiStringLyl', all: any }, attachments: Array<{ __typename?: 'AttachmentOutputLyl', fileId: string, name: string }> }, municipalityApproval: Array<{ __typename?: 'ApprovalOutputLyl', municipalityId: string, approvedAt?: any | null }>, groups: Array<{ __typename?: 'GroupOutputLyl', id: string, createdAt: any, shortId: number, name: string }> }> } };

export type ScanQrQueryVariables = Exact<{
  input: QrDataInputLyl;
}>;


export type ScanQrQuery = { __typename?: 'Query', loyalty: { __typename?: 'loyaltyQueries', scanQr: Array<{ __typename?: 'UtilizedOfferOutputLyl', lastUtilizedAt?: any | null, offer: { __typename?: 'OfferOutputLyl', id: string, submittedAt?: any | null, startsAt: any, endsAt: any, merchant: { __typename?: 'MerchantOutputLyl', id: string, name: string, registrationNumber: string, createdAt: any, legalAddress?: string | null, configuration?: any | null, description?: { __typename?: 'MultiStringLyl', all: any } | null }, message: { __typename?: 'MessageOutputLyl', title?: { __typename?: 'MultiStringLyl', all: any } | null, content: { __typename?: 'MultiStringLyl', all: any } }, municipalityApproval: Array<{ __typename?: 'ApprovalOutputLyl', municipalityId: string, approvedAt?: any | null }>, groups: Array<{ __typename?: 'GroupOutputLyl', id: string, createdAt: any, shortId: number, name: string }> } }> } };

export type UtilizeOfferMutationVariables = Exact<{
  id: Scalars['ID'];
  input: QrDataInputLyl;
}>;


export type UtilizeOfferMutation = { __typename?: 'Mutation', loyalty: { __typename?: 'LoyaltyMutations', utilizeOffer: string } };

export type GetMunicipalitiesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMunicipalitiesQuery = { __typename?: 'Query', municipalities: { __typename?: 'municipalitiesQueries', getMunicipalities: Array<{ __typename?: 'MunicipalityOutputMnc', id: string, number: number, createdAt: any, configuration?: any | null }> } };

export type GetMunicipalityIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetMunicipalityIdQuery = { __typename?: 'Query', municipalities: { __typename?: 'municipalitiesQueries', getMunicipality: { __typename?: 'MunicipalityOutputMnc', id: string } } };

export type OwnAuthenticationContextQueryVariables = Exact<{ [key: string]: never; }>;


export type OwnAuthenticationContextQuery = { __typename?: 'Query', users: { __typename?: 'UsersQueries', ownAuthenticationContext?: { __typename?: 'OwnLocalContextOutput', userId: string, configuration?: any | null, client: { __typename?: 'AuthenticationClientOutput', effectiveId: string, underlyingId: string, tenantId: string, clientPlatform: ClientPlatformUsr } } | null } };


export const AuthenticateDocument = gql`
    mutation authenticate($username: String!, $password: String!) {
  usernameAuthenticator {
    authenticate(input: {username: $username, password: $password}) {
      token
    }
  }
}
    `;
export type AuthenticateMutationFn = Apollo.MutationFunction<AuthenticateMutation, AuthenticateMutationVariables>;

/**
 * __useAuthenticateMutation__
 *
 * To run a mutation, you first call `useAuthenticateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthenticateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authenticateMutation, { data, loading, error }] = useAuthenticateMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useAuthenticateMutation(baseOptions?: Apollo.MutationHookOptions<AuthenticateMutation, AuthenticateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AuthenticateMutation, AuthenticateMutationVariables>(AuthenticateDocument, options);
      }
export type AuthenticateMutationHookResult = ReturnType<typeof useAuthenticateMutation>;
export type AuthenticateMutationResult = Apollo.MutationResult<AuthenticateMutation>;
export type AuthenticateMutationOptions = Apollo.BaseMutationOptions<AuthenticateMutation, AuthenticateMutationVariables>;
export const AddCredentialsDocument = gql`
    mutation addCredentials($username: String!, $password: String!) {
  usernameAuthenticator {
    addCredentials(input: {username: $username, password: $password}) {
      id
      expiresAt
    }
  }
}
    `;
export type AddCredentialsMutationFn = Apollo.MutationFunction<AddCredentialsMutation, AddCredentialsMutationVariables>;

/**
 * __useAddCredentialsMutation__
 *
 * To run a mutation, you first call `useAddCredentialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCredentialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCredentialsMutation, { data, loading, error }] = useAddCredentialsMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useAddCredentialsMutation(baseOptions?: Apollo.MutationHookOptions<AddCredentialsMutation, AddCredentialsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCredentialsMutation, AddCredentialsMutationVariables>(AddCredentialsDocument, options);
      }
export type AddCredentialsMutationHookResult = ReturnType<typeof useAddCredentialsMutation>;
export type AddCredentialsMutationResult = Apollo.MutationResult<AddCredentialsMutation>;
export type AddCredentialsMutationOptions = Apollo.BaseMutationOptions<AddCredentialsMutation, AddCredentialsMutationVariables>;
export const AddCommentDocument = gql`
    mutation addComment($parentId: ID!, $input: MessageInputMsg!) {
  messages {
    addReply(parentId: $parentId, input: $input) {
      id
      createdAt
      content {
        all
      }
    }
  }
}
    `;
export type AddCommentMutationFn = Apollo.MutationFunction<AddCommentMutation, AddCommentMutationVariables>;

/**
 * __useAddCommentMutation__
 *
 * To run a mutation, you first call `useAddCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCommentMutation, { data, loading, error }] = useAddCommentMutation({
 *   variables: {
 *      parentId: // value for 'parentId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCommentMutation(baseOptions?: Apollo.MutationHookOptions<AddCommentMutation, AddCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCommentMutation, AddCommentMutationVariables>(AddCommentDocument, options);
      }
export type AddCommentMutationHookResult = ReturnType<typeof useAddCommentMutation>;
export type AddCommentMutationResult = Apollo.MutationResult<AddCommentMutation>;
export type AddCommentMutationOptions = Apollo.BaseMutationOptions<AddCommentMutation, AddCommentMutationVariables>;
export const AddEventDocument = gql`
    mutation addEvent($municipalityId: ID, $input: PublicEventInputPev!) {
  publicEvents {
    addPublicEvent(municipalityId: $municipalityId, input: $input) {
      id
    }
  }
}
    `;
export type AddEventMutationFn = Apollo.MutationFunction<AddEventMutation, AddEventMutationVariables>;

/**
 * __useAddEventMutation__
 *
 * To run a mutation, you first call `useAddEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEventMutation, { data, loading, error }] = useAddEventMutation({
 *   variables: {
 *      municipalityId: // value for 'municipalityId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddEventMutation(baseOptions?: Apollo.MutationHookOptions<AddEventMutation, AddEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddEventMutation, AddEventMutationVariables>(AddEventDocument, options);
      }
export type AddEventMutationHookResult = ReturnType<typeof useAddEventMutation>;
export type AddEventMutationResult = Apollo.MutationResult<AddEventMutation>;
export type AddEventMutationOptions = Apollo.BaseMutationOptions<AddEventMutation, AddEventMutationVariables>;
export const EditEventDocument = gql`
    mutation editEvent($id: ID!, $input: PublicEventInputPev!) {
  publicEvents {
    editPublicEventContent(id: $id, input: $input) {
      id
    }
  }
}
    `;
export type EditEventMutationFn = Apollo.MutationFunction<EditEventMutation, EditEventMutationVariables>;

/**
 * __useEditEventMutation__
 *
 * To run a mutation, you first call `useEditEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editEventMutation, { data, loading, error }] = useEditEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditEventMutation(baseOptions?: Apollo.MutationHookOptions<EditEventMutation, EditEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditEventMutation, EditEventMutationVariables>(EditEventDocument, options);
      }
export type EditEventMutationHookResult = ReturnType<typeof useEditEventMutation>;
export type EditEventMutationResult = Apollo.MutationResult<EditEventMutation>;
export type EditEventMutationOptions = Apollo.BaseMutationOptions<EditEventMutation, EditEventMutationVariables>;
export const GetEventsListDocument = gql`
    query GetEventsList {
  publicEvents {
    getPublicEvents {
      id
      createdAt
      publishedAt
      duration
      schedule {
        ... on OneOffEventPev {
          startsAt
        }
      }
      location {
        address
      }
      message {
        title {
          all
        }
        preview {
          all
        }
      }
    }
  }
}
    `;

/**
 * __useGetEventsListQuery__
 *
 * To run a query within a React component, call `useGetEventsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventsListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEventsListQuery(baseOptions?: Apollo.QueryHookOptions<GetEventsListQuery, GetEventsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEventsListQuery, GetEventsListQueryVariables>(GetEventsListDocument, options);
      }
export function useGetEventsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventsListQuery, GetEventsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEventsListQuery, GetEventsListQueryVariables>(GetEventsListDocument, options);
        }
export type GetEventsListQueryHookResult = ReturnType<typeof useGetEventsListQuery>;
export type GetEventsListLazyQueryHookResult = ReturnType<typeof useGetEventsListLazyQuery>;
export type GetEventsListQueryResult = Apollo.QueryResult<GetEventsListQuery, GetEventsListQueryVariables>;
export const GetEventDocument = gql`
    query getEvent($id: ID!) {
  publicEvents {
    getPublicEvent(id: $id) {
      id
      publishedAt
      canceledAt
      schedule {
        ... on OneOffEventPev {
          startsAt
        }
      }
      location {
        address
      }
      going
      notGoing
      maybe
      duration
      municipality {
        id
      }
      message {
        id
        title {
          all
        }
        content {
          all
        }
        attachments {
          fileId
          name
        }
        hasRepliesEnabled
        replies {
          id
          createdAt
          content {
            all
          }
          replies {
            id
            createdAt
            content {
              all
            }
          }
        }
        tags {
          id
          groupKey
          title {
            all
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetEventQuery__
 *
 * To run a query within a React component, call `useGetEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEventQuery(baseOptions: Apollo.QueryHookOptions<GetEventQuery, GetEventQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEventQuery, GetEventQueryVariables>(GetEventDocument, options);
      }
export function useGetEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventQuery, GetEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEventQuery, GetEventQueryVariables>(GetEventDocument, options);
        }
export type GetEventQueryHookResult = ReturnType<typeof useGetEventQuery>;
export type GetEventLazyQueryHookResult = ReturnType<typeof useGetEventLazyQuery>;
export type GetEventQueryResult = Apollo.QueryResult<GetEventQuery, GetEventQueryVariables>;
export const DeleteEventDocument = gql`
    mutation deleteEvent($id: ID!) {
  publicEvents {
    deletePublicEvent(id: $id)
  }
}
    `;
export type DeleteEventMutationFn = Apollo.MutationFunction<DeleteEventMutation, DeleteEventMutationVariables>;

/**
 * __useDeleteEventMutation__
 *
 * To run a mutation, you first call `useDeleteEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventMutation, { data, loading, error }] = useDeleteEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEventMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEventMutation, DeleteEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEventMutation, DeleteEventMutationVariables>(DeleteEventDocument, options);
      }
export type DeleteEventMutationHookResult = ReturnType<typeof useDeleteEventMutation>;
export type DeleteEventMutationResult = Apollo.MutationResult<DeleteEventMutation>;
export type DeleteEventMutationOptions = Apollo.BaseMutationOptions<DeleteEventMutation, DeleteEventMutationVariables>;
export const CancelEventDocument = gql`
    mutation cancelEvent($id: ID!) {
  publicEvents {
    cancelPublicEvent(id: $id) {
      id
    }
  }
}
    `;
export type CancelEventMutationFn = Apollo.MutationFunction<CancelEventMutation, CancelEventMutationVariables>;

/**
 * __useCancelEventMutation__
 *
 * To run a mutation, you first call `useCancelEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelEventMutation, { data, loading, error }] = useCancelEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelEventMutation(baseOptions?: Apollo.MutationHookOptions<CancelEventMutation, CancelEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelEventMutation, CancelEventMutationVariables>(CancelEventDocument, options);
      }
export type CancelEventMutationHookResult = ReturnType<typeof useCancelEventMutation>;
export type CancelEventMutationResult = Apollo.MutationResult<CancelEventMutation>;
export type CancelEventMutationOptions = Apollo.BaseMutationOptions<CancelEventMutation, CancelEventMutationVariables>;
export const GetEventTagsDocument = gql`
    query getEventTags($municipalityId: ID) {
  publicEvents {
    tags(municipalityId: $municipalityId) {
      id
      groupKey
      title {
        all
      }
    }
  }
}
    `;

/**
 * __useGetEventTagsQuery__
 *
 * To run a query within a React component, call `useGetEventTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventTagsQuery({
 *   variables: {
 *      municipalityId: // value for 'municipalityId'
 *   },
 * });
 */
export function useGetEventTagsQuery(baseOptions?: Apollo.QueryHookOptions<GetEventTagsQuery, GetEventTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEventTagsQuery, GetEventTagsQueryVariables>(GetEventTagsDocument, options);
      }
export function useGetEventTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventTagsQuery, GetEventTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEventTagsQuery, GetEventTagsQueryVariables>(GetEventTagsDocument, options);
        }
export type GetEventTagsQueryHookResult = ReturnType<typeof useGetEventTagsQuery>;
export type GetEventTagsLazyQueryHookResult = ReturnType<typeof useGetEventTagsLazyQuery>;
export type GetEventTagsQueryResult = Apollo.QueryResult<GetEventTagsQuery, GetEventTagsQueryVariables>;
export const AddOfferDocument = gql`
    mutation addOffer($startsAt: DateTime!, $endsAt: DateTime!, $message: MessageInputLyl!, $municipalityIds: [ID!]!, $groupIds: [ID!]!) {
  loyalty {
    addOffer(
      input: {startsAt: $startsAt, endsAt: $endsAt, message: $message, municipalityIds: $municipalityIds, groupIds: $groupIds}
    ) {
      id
    }
  }
}
    `;
export type AddOfferMutationFn = Apollo.MutationFunction<AddOfferMutation, AddOfferMutationVariables>;

/**
 * __useAddOfferMutation__
 *
 * To run a mutation, you first call `useAddOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOfferMutation, { data, loading, error }] = useAddOfferMutation({
 *   variables: {
 *      startsAt: // value for 'startsAt'
 *      endsAt: // value for 'endsAt'
 *      message: // value for 'message'
 *      municipalityIds: // value for 'municipalityIds'
 *      groupIds: // value for 'groupIds'
 *   },
 * });
 */
export function useAddOfferMutation(baseOptions?: Apollo.MutationHookOptions<AddOfferMutation, AddOfferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddOfferMutation, AddOfferMutationVariables>(AddOfferDocument, options);
      }
export type AddOfferMutationHookResult = ReturnType<typeof useAddOfferMutation>;
export type AddOfferMutationResult = Apollo.MutationResult<AddOfferMutation>;
export type AddOfferMutationOptions = Apollo.BaseMutationOptions<AddOfferMutation, AddOfferMutationVariables>;
export const EditOfferDocument = gql`
    mutation editOffer($id: ID!, $startsAt: DateTime!, $endsAt: DateTime!, $message: MessageInputLyl!, $municipalityIds: [ID!]!, $groupIds: [ID!]!) {
  loyalty {
    editOffer(
      id: $id
      input: {startsAt: $startsAt, endsAt: $endsAt, message: $message, municipalityIds: $municipalityIds, groupIds: $groupIds}
    ) {
      id
    }
  }
}
    `;
export type EditOfferMutationFn = Apollo.MutationFunction<EditOfferMutation, EditOfferMutationVariables>;

/**
 * __useEditOfferMutation__
 *
 * To run a mutation, you first call `useEditOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editOfferMutation, { data, loading, error }] = useEditOfferMutation({
 *   variables: {
 *      id: // value for 'id'
 *      startsAt: // value for 'startsAt'
 *      endsAt: // value for 'endsAt'
 *      message: // value for 'message'
 *      municipalityIds: // value for 'municipalityIds'
 *      groupIds: // value for 'groupIds'
 *   },
 * });
 */
export function useEditOfferMutation(baseOptions?: Apollo.MutationHookOptions<EditOfferMutation, EditOfferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditOfferMutation, EditOfferMutationVariables>(EditOfferDocument, options);
      }
export type EditOfferMutationHookResult = ReturnType<typeof useEditOfferMutation>;
export type EditOfferMutationResult = Apollo.MutationResult<EditOfferMutation>;
export type EditOfferMutationOptions = Apollo.BaseMutationOptions<EditOfferMutation, EditOfferMutationVariables>;
export const DeleteOfferDocument = gql`
    mutation deleteOffer($id: ID!) {
  loyalty {
    deleteOffer(id: $id)
  }
}
    `;
export type DeleteOfferMutationFn = Apollo.MutationFunction<DeleteOfferMutation, DeleteOfferMutationVariables>;

/**
 * __useDeleteOfferMutation__
 *
 * To run a mutation, you first call `useDeleteOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOfferMutation, { data, loading, error }] = useDeleteOfferMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOfferMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOfferMutation, DeleteOfferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOfferMutation, DeleteOfferMutationVariables>(DeleteOfferDocument, options);
      }
export type DeleteOfferMutationHookResult = ReturnType<typeof useDeleteOfferMutation>;
export type DeleteOfferMutationResult = Apollo.MutationResult<DeleteOfferMutation>;
export type DeleteOfferMutationOptions = Apollo.BaseMutationOptions<DeleteOfferMutation, DeleteOfferMutationVariables>;
export const GetMunicipalityGroupsDocument = gql`
    query getMunicipalityGroups($municipalityId: ID!) {
  loyalty {
    getMunicipalityGroups(municipalityId: $municipalityId) {
      id
      createdAt
      shortId
      name
    }
  }
}
    `;

/**
 * __useGetMunicipalityGroupsQuery__
 *
 * To run a query within a React component, call `useGetMunicipalityGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMunicipalityGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMunicipalityGroupsQuery({
 *   variables: {
 *      municipalityId: // value for 'municipalityId'
 *   },
 * });
 */
export function useGetMunicipalityGroupsQuery(baseOptions: Apollo.QueryHookOptions<GetMunicipalityGroupsQuery, GetMunicipalityGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMunicipalityGroupsQuery, GetMunicipalityGroupsQueryVariables>(GetMunicipalityGroupsDocument, options);
      }
export function useGetMunicipalityGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMunicipalityGroupsQuery, GetMunicipalityGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMunicipalityGroupsQuery, GetMunicipalityGroupsQueryVariables>(GetMunicipalityGroupsDocument, options);
        }
export type GetMunicipalityGroupsQueryHookResult = ReturnType<typeof useGetMunicipalityGroupsQuery>;
export type GetMunicipalityGroupsLazyQueryHookResult = ReturnType<typeof useGetMunicipalityGroupsLazyQuery>;
export type GetMunicipalityGroupsQueryResult = Apollo.QueryResult<GetMunicipalityGroupsQuery, GetMunicipalityGroupsQueryVariables>;
export const GetOfferDocument = gql`
    query getOffer($id: ID!) {
  loyalty {
    getOffer(id: $id) {
      id
      merchant {
        id
        name
        registrationNumber
        createdAt
        legalAddress
        description {
          all
        }
        configuration
      }
      submittedAt
      startsAt
      endsAt
      message {
        id
        title {
          all
        }
        content {
          all
        }
        attachments {
          fileId
          name
        }
        hasRepliesEnabled
        replies {
          id
          createdAt
          content {
            all
          }
          replies {
            id
            createdAt
            content {
              all
            }
          }
        }
      }
      municipalityApproval {
        municipalityId
        approvedAt
      }
      groups {
        id
        createdAt
        shortId
        name
      }
    }
  }
}
    `;

/**
 * __useGetOfferQuery__
 *
 * To run a query within a React component, call `useGetOfferQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOfferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOfferQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOfferQuery(baseOptions: Apollo.QueryHookOptions<GetOfferQuery, GetOfferQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOfferQuery, GetOfferQueryVariables>(GetOfferDocument, options);
      }
export function useGetOfferLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOfferQuery, GetOfferQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOfferQuery, GetOfferQueryVariables>(GetOfferDocument, options);
        }
export type GetOfferQueryHookResult = ReturnType<typeof useGetOfferQuery>;
export type GetOfferLazyQueryHookResult = ReturnType<typeof useGetOfferLazyQuery>;
export type GetOfferQueryResult = Apollo.QueryResult<GetOfferQuery, GetOfferQueryVariables>;
export const GetOffersDocument = gql`
    query getOffers {
  loyalty {
    getOffers {
      id
      merchant {
        id
        name
        registrationNumber
        createdAt
        legalAddress
        description {
          all
        }
        configuration
      }
      submittedAt
      startsAt
      endsAt
      message {
        title {
          all
        }
        preview {
          all
        }
        attachments {
          fileId
          name
        }
      }
      municipalityApproval {
        municipalityId
        approvedAt
      }
      groups {
        id
        createdAt
        shortId
        name
      }
    }
  }
}
    `;

/**
 * __useGetOffersQuery__
 *
 * To run a query within a React component, call `useGetOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOffersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOffersQuery(baseOptions?: Apollo.QueryHookOptions<GetOffersQuery, GetOffersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOffersQuery, GetOffersQueryVariables>(GetOffersDocument, options);
      }
export function useGetOffersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOffersQuery, GetOffersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOffersQuery, GetOffersQueryVariables>(GetOffersDocument, options);
        }
export type GetOffersQueryHookResult = ReturnType<typeof useGetOffersQuery>;
export type GetOffersLazyQueryHookResult = ReturnType<typeof useGetOffersLazyQuery>;
export type GetOffersQueryResult = Apollo.QueryResult<GetOffersQuery, GetOffersQueryVariables>;
export const ScanQrDocument = gql`
    query scanQr($input: QrDataInputLyl!) {
  loyalty {
    scanQr(input: $input) {
      offer {
        id
        merchant {
          id
          name
          registrationNumber
          createdAt
          legalAddress
          description {
            all
          }
          configuration
        }
        submittedAt
        startsAt
        endsAt
        message {
          title {
            all
          }
          content {
            all
          }
        }
        municipalityApproval {
          municipalityId
          approvedAt
        }
        groups {
          id
          createdAt
          shortId
          name
        }
      }
      lastUtilizedAt
    }
  }
}
    `;

/**
 * __useScanQrQuery__
 *
 * To run a query within a React component, call `useScanQrQuery` and pass it any options that fit your needs.
 * When your component renders, `useScanQrQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScanQrQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useScanQrQuery(baseOptions: Apollo.QueryHookOptions<ScanQrQuery, ScanQrQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ScanQrQuery, ScanQrQueryVariables>(ScanQrDocument, options);
      }
export function useScanQrLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ScanQrQuery, ScanQrQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ScanQrQuery, ScanQrQueryVariables>(ScanQrDocument, options);
        }
export type ScanQrQueryHookResult = ReturnType<typeof useScanQrQuery>;
export type ScanQrLazyQueryHookResult = ReturnType<typeof useScanQrLazyQuery>;
export type ScanQrQueryResult = Apollo.QueryResult<ScanQrQuery, ScanQrQueryVariables>;
export const UtilizeOfferDocument = gql`
    mutation utilizeOffer($id: ID!, $input: QrDataInputLyl!) {
  loyalty {
    utilizeOffer(id: $id, input: $input)
  }
}
    `;
export type UtilizeOfferMutationFn = Apollo.MutationFunction<UtilizeOfferMutation, UtilizeOfferMutationVariables>;

/**
 * __useUtilizeOfferMutation__
 *
 * To run a mutation, you first call `useUtilizeOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUtilizeOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [utilizeOfferMutation, { data, loading, error }] = useUtilizeOfferMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUtilizeOfferMutation(baseOptions?: Apollo.MutationHookOptions<UtilizeOfferMutation, UtilizeOfferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UtilizeOfferMutation, UtilizeOfferMutationVariables>(UtilizeOfferDocument, options);
      }
export type UtilizeOfferMutationHookResult = ReturnType<typeof useUtilizeOfferMutation>;
export type UtilizeOfferMutationResult = Apollo.MutationResult<UtilizeOfferMutation>;
export type UtilizeOfferMutationOptions = Apollo.BaseMutationOptions<UtilizeOfferMutation, UtilizeOfferMutationVariables>;
export const GetMunicipalitiesDocument = gql`
    query getMunicipalities {
  municipalities {
    getMunicipalities {
      id
      number
      createdAt
      configuration
    }
  }
}
    `;

/**
 * __useGetMunicipalitiesQuery__
 *
 * To run a query within a React component, call `useGetMunicipalitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMunicipalitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMunicipalitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMunicipalitiesQuery(baseOptions?: Apollo.QueryHookOptions<GetMunicipalitiesQuery, GetMunicipalitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMunicipalitiesQuery, GetMunicipalitiesQueryVariables>(GetMunicipalitiesDocument, options);
      }
export function useGetMunicipalitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMunicipalitiesQuery, GetMunicipalitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMunicipalitiesQuery, GetMunicipalitiesQueryVariables>(GetMunicipalitiesDocument, options);
        }
export type GetMunicipalitiesQueryHookResult = ReturnType<typeof useGetMunicipalitiesQuery>;
export type GetMunicipalitiesLazyQueryHookResult = ReturnType<typeof useGetMunicipalitiesLazyQuery>;
export type GetMunicipalitiesQueryResult = Apollo.QueryResult<GetMunicipalitiesQuery, GetMunicipalitiesQueryVariables>;
export const GetMunicipalityIdDocument = gql`
    query getMunicipalityId($id: ID!) {
  municipalities {
    getMunicipality(id: $id) {
      id
    }
  }
}
    `;

/**
 * __useGetMunicipalityIdQuery__
 *
 * To run a query within a React component, call `useGetMunicipalityIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMunicipalityIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMunicipalityIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMunicipalityIdQuery(baseOptions: Apollo.QueryHookOptions<GetMunicipalityIdQuery, GetMunicipalityIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMunicipalityIdQuery, GetMunicipalityIdQueryVariables>(GetMunicipalityIdDocument, options);
      }
export function useGetMunicipalityIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMunicipalityIdQuery, GetMunicipalityIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMunicipalityIdQuery, GetMunicipalityIdQueryVariables>(GetMunicipalityIdDocument, options);
        }
export type GetMunicipalityIdQueryHookResult = ReturnType<typeof useGetMunicipalityIdQuery>;
export type GetMunicipalityIdLazyQueryHookResult = ReturnType<typeof useGetMunicipalityIdLazyQuery>;
export type GetMunicipalityIdQueryResult = Apollo.QueryResult<GetMunicipalityIdQuery, GetMunicipalityIdQueryVariables>;
export const OwnAuthenticationContextDocument = gql`
    query ownAuthenticationContext {
  users {
    ownAuthenticationContext {
      userId
      client {
        effectiveId
        underlyingId
        tenantId
        clientPlatform
      }
      configuration
    }
  }
}
    `;

/**
 * __useOwnAuthenticationContextQuery__
 *
 * To run a query within a React component, call `useOwnAuthenticationContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useOwnAuthenticationContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOwnAuthenticationContextQuery({
 *   variables: {
 *   },
 * });
 */
export function useOwnAuthenticationContextQuery(baseOptions?: Apollo.QueryHookOptions<OwnAuthenticationContextQuery, OwnAuthenticationContextQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OwnAuthenticationContextQuery, OwnAuthenticationContextQueryVariables>(OwnAuthenticationContextDocument, options);
      }
export function useOwnAuthenticationContextLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OwnAuthenticationContextQuery, OwnAuthenticationContextQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OwnAuthenticationContextQuery, OwnAuthenticationContextQueryVariables>(OwnAuthenticationContextDocument, options);
        }
export type OwnAuthenticationContextQueryHookResult = ReturnType<typeof useOwnAuthenticationContextQuery>;
export type OwnAuthenticationContextLazyQueryHookResult = ReturnType<typeof useOwnAuthenticationContextLazyQuery>;
export type OwnAuthenticationContextQueryResult = Apollo.QueryResult<OwnAuthenticationContextQuery, OwnAuthenticationContextQueryVariables>;
import { forwardRef, useEffect, useImperativeHandle } from 'react'
import { EditorContent, useEditor } from '@tiptap/react'
import Underline from '@tiptap/extension-underline'
import TextAlign from '@tiptap/extension-text-align'
import StarterKit from '@tiptap/starter-kit'
import CharacterCount from '@tiptap/extension-character-count'
import Link from '@tiptap/extension-link'
import { Box, FormHelperText, Typography, useTheme } from '@mui/material'
import { TextEditorMenu } from './TextEditorMenu'
import { MAX_LENGTH_ERROR } from '../../constants'

import './TextEditorOverride.css'

interface TextEditorProps {
  maxCharacterCount: number
  disabled: boolean
  value: string | undefined
}

export const TextEditor = forwardRef(({ maxCharacterCount, disabled, value }: TextEditorProps, ref) => {
  const theme = useTheme()

  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      CharacterCount.configure({
        limit: maxCharacterCount,
      }),
      Link,
    ],
    content: value || '',
  })

  useEffect(() => {
    editor && editor.setOptions({ editable: !disabled })
  }, [disabled, editor])

  useImperativeHandle(ref, () => ({
    getText() {
      if (editor) {
        return editor.getHTML()
      }

      return ''
    },
    getCharacterCount() {
      if (editor) {
        return editor.storage.characterCount.characters()
      }

      return 0
    },
  }))

  return (
    <Box sx={{ width: '100%', position: 'relative' }}>
      <TextEditorMenu editor={editor} disabled={disabled} />
      <EditorContent editor={editor} style={{ minHeight: '6rem' }} />
      {editor && (
        <>
          {editor.storage.characterCount.characters() > maxCharacterCount && (
            <FormHelperText sx={{ mt: '3px', ml: '14px', color: theme.palette.error.main }}>{MAX_LENGTH_ERROR}</FormHelperText>
          )}
          <Typography sx={{ mt: 1, width: '100%', textAlign: 'right' }}>
            <Typography
              component="span"
              sx={{
                color: editor.storage.characterCount.characters() > maxCharacterCount ? theme.palette.error.main : undefined,
              }}
            >
              {editor.storage.characterCount.characters()}
            </Typography>{' '}
            / {maxCharacterCount}
          </Typography>
        </>
      )}
    </Box>
  )
})

import { Box, Theme, useTheme } from '@mui/material'
import { alpha, SystemStyleObject } from '@mui/system'

interface CommentWrapProps {
  inline?: boolean
  sx?: boolean | SystemStyleObject<Theme> | ((theme: Theme) => SystemStyleObject<Theme>)
  children: JSX.Element | JSX.Element[]
}

export const CommentWrap = ({ inline, sx, children }: CommentWrapProps) => {
  const theme = useTheme()

  return (
    <Box
      sx={[
        {
          mt: 2,
          ml: inline ? 'auto' : undefined,
          p: 1,
          borderRadius: 2,
          width: inline ? '90%' : '100%',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: inline ? alpha(theme.palette.primary.main, 0.05) : alpha(theme.palette.primary.main, 0.1),
          boxSizing: 'border-box',
        },
        sx || {},
      ]}
    >
      {children}
    </Box>
  )
}

import { Box } from '@mui/material'
import { QrButton } from '../../components'

export const Home = () => {
  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <QrButton />
    </Box>
  )
}

import React from 'react'
import { Checkbox, FormControlLabel, FormGroup, Link, Theme, Typography, useTheme } from '@mui/material'

interface TocDisclaimerProps {
  acceptedToc: boolean
  disabled: boolean
  onOpenTerms: () => void
  onTocDisclaimer: (hasAcceptedToc: boolean) => void
}

export const TocDisclaimer = ({ acceptedToc, disabled, onOpenTerms, onTocDisclaimer }: TocDisclaimerProps) => {
  const theme = useTheme() as Theme

  const onClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()

    if (disabled) {
      return
    }

    onOpenTerms()
  }

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onTocDisclaimer(event.target.checked)
  }

  return (
    <FormGroup>
      <FormControlLabel
        control={<Checkbox checked={acceptedToc} disabled={disabled} onChange={onChange} />}
        label={
          <>
            <Typography component="span">Atzīmējot, Jūs piekrītat lietotnes </Typography>
            <Link
              sx={{
                color: disabled ? theme.palette.action.disabled : undefined,
                textDecorationColor: disabled ? theme.palette.action.disabled : undefined,
              }}
              href="#"
              onClick={onClick}
            >
              lietošanas noteikumiem
            </Link>
            <Typography component="span">, privātuma politikai un notifikāciju paziņojumiem.</Typography>
          </>
        }
      />
    </FormGroup>
  )
}

import React, { useState, FormEvent } from 'react'
import { Box, Typography, TextField, Button } from '@mui/material'
import { FLEX_DIRECTION_COLUMN } from '../../../style/sx'
import { DelayedLoader } from '../../DelayedLoader'

interface UsernameAuthProps {
  acceptedToc: boolean
  children: JSX.Element
  loading: boolean
  onLogin: (uesrname: string, password: string) => void
  onRegister: () => void
}

export const UsernameAuth = ({ acceptedToc, children, loading, onLogin, onRegister }: UsernameAuthProps) => {
  const [usernameField, setUsernameField] = useState('')
  const [passwordField, setPasswordField] = useState('')

  const onChangeUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsernameField(event.target.value)
  }
  const onChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordField(event.target.value)
  }

  const onSubmit = (event: FormEvent) => {
    event.preventDefault()

    onLogin(usernameField, passwordField)
  }

  const onRegisterBtnClick = () => {
    onRegister()
  }

  return (
    <Box component="form" onSubmit={onSubmit} sx={FLEX_DIRECTION_COLUMN} noValidate>
      <Box component="label" sx={FLEX_DIRECTION_COLUMN}>
        <Typography component="span" textAlign="center">
          Lietotājvārds
        </Typography>
        <TextField onChange={onChangeUsername} type="email" disabled={loading} value={usernameField} />
      </Box>
      <Box component="label" sx={FLEX_DIRECTION_COLUMN}>
        <Typography component="span" textAlign="center">
          Parole
        </Typography>
        <TextField type="password" disabled={loading} value={passwordField} onChange={onChangePassword} />
      </Box>
      <Box sx={{ mt: 2 }}>{children}</Box>
      <Button
        sx={{ mt: 4, mr: 'auto', ml: 'auto', width: '100%', visibility: acceptedToc ? 'visible' : 'hidden' }}
        size="large"
        variant="contained"
        disabled={loading}
        type="submit"
      >
        {loading ? <DelayedLoader loaderSize={26.5} /> : <>Pieslēgties</>}
      </Button>
      <Button
        sx={{ mt: 4, mr: 'auto', ml: 'auto', width: '100%' }}
        size="large"
        variant="contained"
        disabled={loading}
        onClick={onRegisterBtnClick}
        type="button"
      >
        {loading ? <DelayedLoader loaderSize={26.5} /> : <>Reģistrēties</>}
      </Button>
    </Box>
  )
}

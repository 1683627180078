import { AppBar, IconButton, Toolbar, Typography, useMediaQuery } from '@mui/material'
import { MenuIcon } from '../../icons'
import { NormalizedNavLink } from '../NormalizedNavLink'

interface HeaderProps {
  logo?: JSX.Element
  onMenuToggle: () => void
}

export const Header = ({ logo, onMenuToggle }: HeaderProps) => {
  const matches = useMediaQuery('(min-width:360px)')

  return (
    <AppBar
      position="static"
      color="inherit"
      sx={{
        paddingTop: 'env(safe-area-inset-top, 0)',
        paddingRight: 'env(safe-area-inset-right, 0)',
        paddingLeft: 'env(safe-area-inset-left, 0)',
      }}
    >
      <Toolbar sx={{ minHeight: '72px' }}>
        {logo && (
          <NormalizedNavLink to="/" style={{ width: '4rem', position: 'absolute' }}>
            {logo}
          </NormalizedNavLink>
        )}
        <Typography
          variant="h1"
          sx={{ width: '100%', textTransform: 'uppercase', textAlign: 'center', fontSize: '1rem', fontWeight: 500 }}
        >
          Pašvaldību lojalitātes
        </Typography>
        <IconButton size="large" onClick={onMenuToggle} aria-label="Atvērt izvēlni" sx={{ position: 'absolute', right: 0 }}>
          <MenuIcon size={matches ? 'medium' : 'small'} color={'primary'} />
        </IconButton>
      </Toolbar>
    </AppBar>
  )
}

import { Box, Button, Typography } from '@mui/material'
import { format } from 'date-fns'
import { useEffect } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { QrButton, ResultWrap } from '../../components'
import { DEFAULT_DATE_TIME_FORMAT } from '../../constants'
import { useUtilizeOfferMutation } from '../../graphql'
import { useSnackbarStore, useVerifyStore } from '../../store'

export const ResultOffer = () => {
  const navigate = useNavigate()

  const [utilizeMutation, { error, loading }] = useUtilizeOfferMutation()

  const { offer, parsedQrCode } = useVerifyStore()
  const showSnackbar = useSnackbarStore((state) => state.showSnackbar)

  useEffect(() => {
    if (error) {
      showSnackbar('Kaut kas nogāja greizi!', { severity: 'error' })
    }
  }, [error, showSnackbar])

  if (offer == null) {
    return <Navigate to="/" />
  }

  const onUtilize = () => {
    if (offer && parsedQrCode) {
      utilizeMutation({ variables: { id: offer.offer.id, input: parsedQrCode } }).then(() => {
        showSnackbar('Atlaide piemērota!')
        navigate('/', { replace: true })
      })
    }
  }

  return (
    <ResultWrap backgroundColor="#8EB2A7">
      <Typography
        variant="h1"
        sx={{
          mt: 2,
          textTransform: 'uppercase',
          lineHeight: 1.5,
        }}
      >
        Kods atpazīts
      </Typography>
      <Box sx={{ mt: 4 }}>
        <Typography>Pēdējo reizi validēts:</Typography>
        <Typography sx={{ color: '#B80000', fontSize: '2rem' }}>
          {offer.lastUtilizedAt ? format(new Date(offer.lastUtilizedAt), DEFAULT_DATE_TIME_FORMAT) : '--.--.----. --:--'}
        </Typography>
      </Box>
      <Typography sx={{ mt: 4, fontSize: '2rem', fontWeight: 700 }}>{offer.offer.message.title?.all.lv}</Typography>
      <Button onClick={onUtilize} disabled={loading} sx={{ mt: 4 }} variant="contained" size="large">
        Piemērot
      </Button>
      <QrButton />
    </ResultWrap>
  )
}

import { Camera, CameraResultType } from '@capacitor/camera'
import { Button } from '@mui/material'
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera'
import { CameraFile } from '../../interfaces'

interface CameraButtonProps {
  disabled?: boolean
  onAppImage: (file: CameraFile) => void
}

export const CameraButton = ({ disabled, onAppImage }: CameraButtonProps) => {
  const onCamera = async () => {
    const image = await Camera.getPhoto({
      quality: 70,
      width: 1024,
      promptLabelHeader: 'Pievienot fotoattēlu',
      promptLabelPicture: 'Atvērt kameru',
      promptLabelPhoto: 'Atvērt galeriju',
      resultType: CameraResultType.Uri,
    })

    if (image) {
      onAppImage({
        path: image.path || '',
        webPath: image.webPath || '',
      })
    }
  }

  return (
    <Button variant="contained" onClick={onCamera} size="large" disabled={disabled}>
      <PhotoCameraIcon sx={{ mr: 2 }} />
      Pievienot attēlus
    </Button>
  )
}

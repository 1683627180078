import { Http, HttpHeaders } from '@capacitor-community/http'

export const nativeFetch = async (url: string, options: RequestInit) => {
  let data = options.body

  if (typeof options.body === 'string') {
    try {
      data = JSON.parse(options.body)
    } catch {}
  }

  const response = await Http.post({
    url,
    headers: options.headers as HttpHeaders | undefined,
    data,
  })

  /*
   * remaps plugin's HttpResponse to Response
   * as HttpResponse does not contain many properties that are found on native Response
   * we either implement them ourselves or ignore
   */
  return {
    status: response.status,
    headers: response.headers,
    body: response.data,
    url: response.url,
    text: () => {
      return new Promise<string>((resolve) => {
        if (typeof response.data === 'object' && response.data !== null) {
          try {
            return resolve(JSON.stringify(response.data))
          } catch {
            return resolve(response.data)
          }
        }

        return resolve(response.data)
      })
    },
  } as unknown as Response
}

import { useSvgParams } from '../../hooks'
import { SvgIcon } from '@mui/material'
import { SvgIconsProps } from '../../interfaces'

export const OffersIcon = ({ color, size, sx }: SvgIconsProps) => {
  const [dimension, fill] = useSvgParams({ size, color })

  return (
    <SvgIcon viewBox="0 0 66 66" sx={[{ width: dimension, height: dimension }, sx || {}]}>
      <path
        fill={fill}
        d="M16.81 51.44c-.58 0-1.15-.22-1.59-.66-.88-.88-.88-2.3 0-3.18L47.6 15.22c.88-.88 2.3-.88 3.18 0 .88.88.88 2.3 0 3.18L18.4 50.78c-.44.44-1.01.66-1.59.66zM20.28 28.31c-4.43 0-8.03-3.6-8.03-8.03s3.6-8.03 8.03-8.03 8.03 3.6 8.03 8.03-3.6 8.03-8.03 8.03zm0-11.56a3.53 3.53 0 1 0-.001 7.059 3.53 3.53 0 0 0 .001-7.059zM45.72 53.75c-4.43 0-8.03-3.6-8.03-8.03s3.6-8.03 8.03-8.03 8.03 3.6 8.03 8.03-3.6 8.03-8.03 8.03zm0-11.56a3.53 3.53 0 1 0-.001 7.059 3.53 3.53 0 0 0 .001-7.059z"
      />
    </SvgIcon>
  )
}

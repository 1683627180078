// import React, { FormEvent, useState } from 'react'
import { Box, Typography } from '@mui/material'
// import { FormRow } from '../../style/styled-components'
// import { useNavigate } from 'react-router-dom'
// import { useOwnAuthenticationContextQuery } from '../../graphql'
// import { DelayedLoader } from '../../components';
import { useUserContext } from '../../store'
import { FLEX_DIRECTION_COLUMN } from '../../style/sx'

// interface FormData {
//   name: string,
// }

export const Profile = () => {
  // const navigate = useNavigate()

  const username = useUserContext((state) => state.username)

  // const  {data: dataProfile, loading: loadingProfile, error: errorProfile } = useOwnAuthenticationContextQuery()

  // const [formData, setFormData] = useState<FormData>({
  //   name: username,
  // })

  // const onSubmit = async (event: FormEvent) => {
  // }

  // const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setFormData({
  //     ...formData,
  //     [event.target.name]: event.target.value,
  //   })
  // }

  // const goBack = () => {
  //   navigate(-1)
  // }

  // if(loadingProfile){
  //   return <DelayedLoader />
  // }

  return (
    <Box component="main" sx={FLEX_DIRECTION_COLUMN}>
      <Typography>Lietotājvārds: {username}</Typography>
    </Box>
    // <Box component="form" onSubmit={onSubmit}>
    //   <FormRow component="label" sx={{ mt: 0 }}>
    //     <Typography component="span">Nosaukums</Typography>
    //     <TextField
    //       onChange={onChange}
    //       name="name"
    //       value={formData.name} />
    //   </FormRow>

    //   <FormRow>
    //     <Button variant="contained" type="submit">Saglabāt</Button>
    //   </FormRow>
    //   <FormRow>
    //     <Button variant="outlined" onClick={goBack}>Atpakaļ</Button>
    //   </FormRow>
    // </Box>
  )
}

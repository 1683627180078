import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { ItemButton, InfiniteList } from '../../../components'
import { useDeleteOfferMutation, useGetOffersQuery } from '../../../graphql'
import { OfferListItem } from '../../../interfaces'
import { mapOfferList } from '../../../utils'
import { DEFAULT_ENTRIES_PER_PAGE } from '../../../constants'
import { useConfirmStore } from '../../../store'
import { Box, Button } from '@mui/material'

export const OffersHome = () => {
  const navigate = useNavigate()
  const [currentPage, setCurrentPage] = useState(1)

  const { data, loading, refetch } = useGetOffersQuery()
  const [offerList, setOfferList] = useState<OfferListItem[]>([])
  const [pagedOfferList, setPagedOfferList] = useState<OfferListItem[]>([])
  const [deleteOffer] = useDeleteOfferMutation()
  const confirm = useConfirmStore((state) => state.confirm)

  useEffect(() => {
    if (data) {
      const list = mapOfferList(data)

      setOfferList(list)
      setPagedOfferList(list.slice(0, DEFAULT_ENTRIES_PER_PAGE))
    }
  }, [data])

  const onNewOffer = () => {
    navigate('/offers/new')
  }

  const onEdit = (id: string) => {
    navigate('/offers/edit/' + id)
  }

  const onView = (id: string) => {
    navigate('/offers/item/' + id)
  }

  const onDelete = async (id: string) => {
    const response = await confirm({
      promptTitle: 'Vai tiešām dzēst šo piedāvājumu?',
      confirmButtonColor: 'error',
      confirmButtonLabel: 'Dzēst',
    })

    if (response && id) {
      deleteOffer({ variables: { id: id } }).then(() => {
        refetch()
      })
    }
  }

  const onLoadMore = () => {
    const nextPage = currentPage + 1
    const nextEntriesCount = nextPage * DEFAULT_ENTRIES_PER_PAGE

    setCurrentPage(nextPage)

    if (nextEntriesCount >= offerList.length) {
      setPagedOfferList(offerList)
      return
    }

    setPagedOfferList(offerList.slice(0, nextEntriesCount))
  }

  const renderListItem = (offerItem: OfferListItem) => {
    return (
      <ItemButton
        title={offerItem.title}
        id={offerItem.id}
        preview={offerItem.preview}
        disabled={offerItem.approvedAt !== null}
        onView={() => onView(offerItem.id)}
        onEdit={() => onEdit(offerItem.id)}
        onDelete={() => onDelete(offerItem.id)}
        validityInterval={offerItem.validityInterval}
        approvedAt={offerItem.approvedAt !== null}
      />
    )
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-around',
          position: 'fixed',
          zIndex: 2,
          bottom: 'env(safe-area-inset-bottom, 0)',
          left: 'env(safe-area-inset-left, 0)',
          right: 'env(safe-area-inset-right, 0)',
        }}
      >
        <Button sx={{ maxWidth: '1400px', m: 1, flexGrow: 1 }} variant="contained" onClick={onNewOffer}>
          Pievienot jaunu
        </Button>
      </Box>
      <InfiniteList loading={loading} renderFn={renderListItem} onLoadMore={onLoadMore} list={pagedOfferList} />
    </>
  )
}

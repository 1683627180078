import { useState } from 'react'
import { useUserContext } from '../../store'
import { Http } from '@capacitor-community/http'
import { CameraFile } from '../../interfaces'
import { GRAPHQL_API_FILE_URL } from '../../constants'

export const useUploadCameraFiles = () => {
  const token = useUserContext((state) => state.token)

  const [uploadedIds, setUploadedIds] = useState<string[]>([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const uploadCameraFiles = async (files: CameraFile[]) => {
    setUploadedIds([])
    setLoading(true)
    setError(false)

    const request = files.map((file) => {
      return Http.uploadFile({
        url: GRAPHQL_API_FILE_URL,
        method: 'POST',
        filePath: file.path,
        name: 'file',
        headers: {
          authorization: token ? `Token ${token}` : '',
        },
      })
    })

    return Promise.all(request)
      .then((response) => {
        const uploadIds: string[] = response
          .filter((entry) => typeof entry.data === 'object' && entry.data !== null && entry.status === 200)
          .map((entry) => entry.data.id)

        setUploadedIds(uploadIds)
        setLoading(false)
        return uploadIds
      })
      .catch(() => setError(true))
  }

  return {
    uploadCameraFiles,
    uploadedIds,
    loading,
    error,
  }
}

import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { DelayedLoader } from '../../components'
import { QrDataInputLyl, useScanQrLazyQuery, UtilizedOfferOutputLyl } from '../../graphql'
import { useVerifyStore } from '../../store'

export const Verify = () => {
  const navigate = useNavigate()

  const [scanQrQuery, { data, error }] = useScanQrLazyQuery()
  const { rawQrCode, setParsedQrCode, setError, setOffer, setOffers } = useVerifyStore()

  useEffect(() => {
    const parseQrContent = (content: string): QrDataInputLyl | null => {
      try {
        const tempArr = content.split('://')

        if (tempArr.length !== 2) {
          return null
        }

        const tempArr2 = tempArr[1].split('/')

        if (tempArr2.length < 9) {
          return null
        }

        const parsedQrCode = {
          schemaPrefix: tempArr[0],
          applicationId: Number(tempArr2[0]),
          issuerId: Number(tempArr2[1]),
          batchNumber: Number(tempArr2[2]),
          issuedAt: Number(tempArr2[3]),
          expiresAt: Number(tempArr2[4]),
          groupIds: tempArr2[5].length > 0 ? tempArr2[5].split(',').map((val) => Number(val)) : [],
          userId: tempArr2[6],
          municipalityShortId: Number(tempArr2[7]),
          signatureBase64: tempArr2.slice(8).join('/'), // signatureBase64 can contain '/' char so it can be split in multiple tempArr2 places
        }

        setParsedQrCode(parsedQrCode)

        return parsedQrCode
      } catch {
        return null
      }
    }

    if (rawQrCode) {
      const parsedQrContent = parseQrContent(rawQrCode)

      if (parsedQrContent) {
        scanQrQuery({ variables: { input: parsedQrContent } })
      } else {
        setError('Lojalitātes karte nav atpazīta!')
        navigate('/result/error', { replace: true })
      }
    } else {
      navigate('/', { replace: true })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (data) {
      const result = data.loyalty.scanQr as UtilizedOfferOutputLyl[]

      if (result && result.length) {
        if (result.length === 1) {
          setOffer(result[0])
          navigate('/result/success/offer', { replace: true })
        } else {
          setOffers(result)
          navigate('/result/success/offers', { replace: true })
        }
      } else {
        setError('Nav atbilstošu piedāvājumu!')
        navigate('/result/error', { replace: true })
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    if (error) {
      setError('Lojalitātes karte nav atpazīta!')
      navigate('/result/error', { replace: true })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  return <DelayedLoader />
}

import { Box, Button, useMediaQuery } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { VIEWPORT_MD } from '../../constants'

interface FormActionsProps {
  disabled: boolean
}

export const FormActions = ({ disabled }: FormActionsProps) => {
  const navigate = useNavigate()
  const matches = useMediaQuery(VIEWPORT_MD)

  const goBack = () => {
    navigate(-1)
  }

  return (
    <Box
      sx={{
        pt: 4,
        textAlign: matches ? 'right' : undefined,
      }}
    >
      <Button variant="contained" disabled={disabled} fullWidth={!matches} size="large" type="submit">
        Saglabāt
      </Button>
      <Button
        variant="outlined"
        disabled={disabled}
        fullWidth={!matches}
        size="large"
        sx={{
          mt: matches ? undefined : 2,
          ml: matches ? 2 : undefined,
        }}
        onClick={goBack}
      >
        Atpakaļ
      </Button>
    </Box>
  )
}

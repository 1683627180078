import { Button, List, ListItem, Typography } from '@mui/material'
import { Navigate, useNavigate } from 'react-router-dom'
import { QrButton, ResultWrap } from '../../components'
import { useVerifyStore } from '../../store'

export const ResultOffers = () => {
  const navigate = useNavigate()

  const { offers, setOffer } = useVerifyStore()

  if (offers == null) {
    return <Navigate to="/" />
  }

  const onOffer = (index: number) => {
    if (offers) {
      setOffer(offers[index])
      navigate('/result/success/offer')
    }
  }

  return (
    <ResultWrap backgroundColor="#8EB2A7">
      <Typography
        variant="h1"
        sx={{
          mt: 2,
          textTransform: 'uppercase',
          lineHeight: 1.5,
        }}
      >
        Kods atpazīts
      </Typography>
      <Typography sx={{ mt: 4 }}>Pieejamās lojalitātes atlaides:</Typography>
      <List disablePadding>
        {offers &&
          offers.map((offer, index) => (
            <ListItem disablePadding key={index} sx={{ mt: 2 }}>
              <Button onClick={() => onOffer(index)} fullWidth variant="contained">
                {offer.offer.message.title?.all.lv}
              </Button>
            </ListItem>
          ))}
      </List>
      <QrButton />
    </ResultWrap>
  )
}

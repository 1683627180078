import React, { FormEvent, useEffect, useRef, useState } from 'react'
import { Box, Button, Checkbox, FormControlLabel, FormHelperText, TextField, Typography } from '@mui/material'
import { FormRow } from '../../../style/styled-components'
import { useNavigate, useParams } from 'react-router-dom'
import {
  AttachmentOutputLyl,
  GroupOutputLyl,
  OfferInputLyl,
  useAddOfferMutation,
  useEditOfferMutation,
  useGetMunicipalitiesQuery,
  useGetMunicipalityGroupsLazyQuery,
  useGetOfferQuery,
} from '../../../graphql'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import {
  DEFAULT_DATE_TIME_FORMAT,
  MUNICIPALITIES_ID_MAP,
  ACCEPTED_FILE_TYPES,
  ACCEPTED_UPLOAD_FILE_TYPES,
  MAX_RICH_TEXT_LENGTH,
} from '../../../constants'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { mapAttachments, remapFileList, validateOfferForm } from '../../../utils'
import { DelayedLoader, StaticGallery, FileUploadButton, CameraButton, TextEditor } from '../../../components'
import { CameraFile, OfferNew, AttachmentItem, TextEditorRef, StringObject } from '../../../interfaces'
import { useUploadCameraFiles, useUploadGenericFiles } from '../../../api'
import { Capacitor } from '@capacitor/core'

export const OfferForm = () => {
  const { id } = useParams()
  const editorRef = useRef<TextEditorRef>(null)

  const navigate = useNavigate()

  const [formData, setFormData] = useState<OfferNew>({
    title: '',
    content: '',
    startsAt: null,
    endsAt: null,
    groupIds: [],
    municipalitiesIds: [],
  })
  const [formErrors, setFormErrors] = useState<StringObject>({})
  const [fileList, setFileList] = useState<(File | CameraFile | AttachmentItem)[]>([])
  const [images, setImages] = useState<AttachmentItem[]>([])

  const [groups, setGroups] = useState<{ [index: string]: GroupOutputLyl[] }>()

  const [addOfferMutation] = useAddOfferMutation()
  const [editOfferMutation] = useEditOfferMutation()
  const { uploadCameraFiles } = useUploadCameraFiles()
  const { uploadGenericFiles } = useUploadGenericFiles()

  const { data: dataOffer, loading: loadingOffer } = useGetOfferQuery({
    variables: { id: id ? id : '0' },
    skip: id === undefined,
  })
  const [loading, setLoading] = useState(false)

  const { data: dataMunicipalities, loading: loadingMunicipalities } = useGetMunicipalitiesQuery()
  const [getGroups, { loading: loadingGroups }] = useGetMunicipalityGroupsLazyQuery()

  const errorHandler = () => {
    setLoading(false)
  }

  useEffect(() => {
    setImages(remapFileList(fileList))
  }, [fileList])

  useEffect(() => {
    if (dataOffer) {
      const data = dataOffer.loyalty.getOffer

      setFormData({
        title: data.message.title?.all['lv'],
        content: data.message.content?.all['lv'],
        startsAt: new Date(data.startsAt),
        endsAt: new Date(data.endsAt),
        groupIds: data.groups.map((val) => val.id),
        municipalitiesIds: data.municipalityApproval.map((m) => m.municipalityId),
      })

      setFileList(mapAttachments(data.message.attachments as AttachmentOutputLyl[]))

      data.municipalityApproval.forEach((m) => {
        getGroups({
          variables: {
            municipalityId: m.municipalityId,
          },
        }).then((response) => {
          if (response.data?.loyalty.getMunicipalityGroups !== undefined) {
            setGroups({ ...groups, [m.municipalityId]: response.data?.loyalty.getMunicipalityGroups })
          }
        })
      })
    }
    // eslint-disable-next-line
  }, [dataOffer])

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault()

    const [isValid, errors, startsAt, endsAt] = validateOfferForm(formData)

    setFormErrors(errors)

    if (!isValid) return

    setLoading(true)

    const newCameraFiles: CameraFile[] = []
    const newWebFiles: File[] = []

    const attachedFileIds: string[] = []

    for (const file of fileList) {
      if (file instanceof File) {
        newWebFiles.push(file)
        continue
      }

      if ('webPath' in file) {
        newCameraFiles.push(file)
        continue
      }

      if (file.id) {
        attachedFileIds.push(file.id)
      }
    }

    Promise.all([uploadGenericFiles(newWebFiles), uploadCameraFiles(newCameraFiles)]).then(
      ([uploadedWebFiles, uploadedCameraFiles]) => {
        const content = editorRef.current ? editorRef.current.getText() : ''
        const data: OfferInputLyl = {
          message: {
            title: { lv: formData.title },
            content: { lv: content },
            attachedFileIds: attachedFileIds.concat(uploadedWebFiles || [], uploadedCameraFiles || []),
          },
          startsAt,
          endsAt,
          groupIds: formData.groupIds,
          municipalityIds: formData.municipalitiesIds,
        }

        if (id) {
          editOfferMutation({ variables: { id: id, ...data } }).then(() => {
            goBack()
          })
        } else {
          addOfferMutation({ variables: data })
            .then(() => {
              goBack()
            })
            .catch(errorHandler)
        }
      }
    )
  }

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    })

    setFormErrors({
      ...formErrors,
      [event.target.name]: '',
    })
  }

  const onStartsAtChange = (value: Date | null) => {
    if (value) {
      setFormData({
        ...formData,
        startsAt: value,
      })
    }

    setFormErrors({
      ...formErrors,
      startsAt: '',
    })
  }

  const onEndsAtChange = (value: Date | null) => {
    if (value) {
      setFormData({
        ...formData,
        endsAt: value,
      })
    }

    setFormErrors({
      ...formErrors,
      endsAt: '',
    })
  }

  const loadGroups = (id: string) => {
    if (!groups || groups[id] === undefined) {
      getGroups({
        variables: {
          municipalityId: id,
        },
      }).then((response) => {
        if (response.data?.loyalty.getMunicipalityGroups !== undefined) {
          setGroups({ ...groups, [id]: response.data?.loyalty.getMunicipalityGroups })
        }
      })
    }
  }

  const onMunicipalityChange = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
    if (event.target.checked) {
      setFormData({
        ...formData,
        municipalitiesIds: [...formData.municipalitiesIds, id],
      })
      loadGroups(id)
    } else {
      let municipalitiesArr = formData.municipalitiesIds
      const index = municipalitiesArr.indexOf(id)
      if (index > -1) {
        municipalitiesArr.splice(index, 1)
        setFormData({
          ...formData,
          municipalitiesIds: municipalitiesArr,
        })

        // uncheck all child groups
        if (groups && groups[id]) {
          groups[id].forEach((group) => {
            let groupsArr = formData.groupIds
            const index = groupsArr.indexOf(group.id)
            if (index > -1) {
              groupsArr.splice(index, 1)
              setFormData({
                ...formData,
                groupIds: groupsArr,
              })
            }
          })
        }
      }
    }

    setFormErrors({
      ...formErrors,
      municipalities: '',
    })
  }

  const onGroupChange = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
    if (event.target.checked) {
      setFormData({
        ...formData,
        groupIds: [...formData.groupIds, id],
      })
    } else {
      let groupsArr = formData.groupIds
      const index = groupsArr.indexOf(id)
      if (index > -1) {
        groupsArr.splice(index, 1)
        setFormData({
          ...formData,
          groupIds: groupsArr,
        })
      }
    }
  }

  const onWebImage = (files: File[]) => {
    setFileList([...fileList, ...files])
  }

  const onAppImage = (file: CameraFile) => {
    setFileList([...fileList, file])
  }

  const onDeleteImage = (index: number) => {
    const copy = [...fileList]
    copy.splice(index, 1)
    setFileList(copy)
  }

  const goBack = () => {
    navigate(-1)
  }

  if (id && loadingOffer) {
    return <DelayedLoader />
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box component="form" onSubmit={onSubmit}>
        <FormRow component="label" sx={{ mt: 0 }}>
          <Typography component="span">Nosaukums</Typography>
          <TextField
            onChange={onChange}
            name="title"
            disabled={loading}
            value={formData.title}
            helperText={formErrors['title']}
            error={!!formErrors['title']}
          />
        </FormRow>
        <FormRow component="div">
          <Typography component="span">Apraksts</Typography>
          <TextEditor
            maxCharacterCount={MAX_RICH_TEXT_LENGTH}
            disabled={loading}
            value={dataOffer?.loyalty.getOffer.message.content.all['lv'] || formData.content}
            ref={editorRef}
          />
        </FormRow>
        {fileList.length > 0 && (
          <FormRow>
            <StaticGallery images={images} onDelete={onDeleteImage} canDelete={true} />
          </FormRow>
        )}
        {Capacitor.getPlatform() === 'web' ? (
          <FormRow>
            <FileUploadButton
              acceptedTypeExtensions={ACCEPTED_FILE_TYPES}
              acceptedTypes={ACCEPTED_UPLOAD_FILE_TYPES}
              onFileSelect={onWebImage}
              disabled={loading}
            />
          </FormRow>
        ) : (
          <FormRow>
            <CameraButton onAppImage={onAppImage} />
          </FormRow>
        )}
        <FormRow component="label">
          <Typography component="span">Laiks no</Typography>
          <DateTimePicker
            ampm={false}
            disabled={loading}
            minDateTime={new Date()}
            maxDate={formData.endsAt || undefined}
            inputFormat={DEFAULT_DATE_TIME_FORMAT}
            renderInput={(props) => (
              <TextField
                {...props}
                InputLabelProps={{
                  shrink: true,
                }}
                helperText={formErrors['startsAt']}
                error={!!formErrors['startsAt']}
              />
            )}
            value={formData.startsAt}
            onChange={onStartsAtChange}
          />
        </FormRow>
        <FormRow component="label">
          <Typography component="span">Laiks līdz</Typography>
          <DateTimePicker
            ampm={false}
            disabled={loading || !formData.startsAt}
            minDateTime={formData.startsAt || undefined}
            inputFormat={DEFAULT_DATE_TIME_FORMAT}
            renderInput={(props) => (
              <TextField
                {...props}
                InputLabelProps={{
                  shrink: true,
                }}
                helperText={formErrors['endsAt']}
                error={!!formErrors['endsAt']}
              />
            )}
            value={formData.endsAt}
            onChange={onEndsAtChange}
          />
        </FormRow>

        <FormRow component="label">
          <Typography component="span">Grupas</Typography>
          {loadingMunicipalities && <DelayedLoader />}
          {!loadingMunicipalities &&
            dataMunicipalities &&
            dataMunicipalities.municipalities.getMunicipalities.map((mun, index) => (
              <Box key={index}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={loading}
                      checked={formData.municipalitiesIds.indexOf(mun.id) !== -1}
                      onChange={(event) => onMunicipalityChange(event, mun.id)}
                    />
                  }
                  label={MUNICIPALITIES_ID_MAP[mun.id] ? MUNICIPALITIES_ID_MAP[mun.id] : mun.id}
                />
                {(groups === undefined || (groups && groups[mun.id] === undefined)) &&
                  formData.municipalitiesIds.indexOf(mun.id) !== -1 &&
                  loadingGroups && (
                    <Box>
                      <DelayedLoader />
                    </Box>
                  )}
                {groups && groups[mun.id] && formData.municipalitiesIds.indexOf(mun.id) !== -1 && (
                  <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                    {groups[mun.id].map((group, index) => (
                      <FormControlLabel
                        key={index}
                        control={
                          <Checkbox
                            disabled={loading}
                            checked={formData.groupIds.indexOf(group.id) !== -1}
                            onChange={(event) => onGroupChange(event, group.id)}
                          />
                        }
                        label={group.name}
                      />
                    ))}
                  </Box>
                )}
              </Box>
            ))}
          {formErrors['municipalities'] && (
            <FormHelperText sx={{ ml: '14px' }} error>
              {formErrors['municipalities']}
            </FormHelperText>
          )}
        </FormRow>

        <FormRow>
          <Button variant="contained" disabled={loading} type="submit">
            Saglabāt
          </Button>
        </FormRow>
        <FormRow>
          <Button variant="outlined" disabled={loading} onClick={goBack}>
            Atpakaļ
          </Button>
        </FormRow>
      </Box>
    </LocalizationProvider>
  )
}

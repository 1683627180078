import { useState } from 'react'
import { Box, Button, Collapse, Divider, ListItemButton, Typography, Chip, useTheme } from '@mui/material'

interface ItemButtonProps {
  title: string
  id: string
  preview: string
  disabled?: boolean
  onView: (id: string) => void
  onEdit: (id: string) => void
  onDelete: (id: string) => void
  validityInterval: string
  approvedAt: boolean
}

export const ItemButton = ({
  title,
  id,
  preview,
  disabled,
  onView,
  onEdit,
  onDelete,
  approvedAt,
  validityInterval,
}: ItemButtonProps) => {
  const [expanded, setExpanded] = useState(false)
  const theme = useTheme()

  return (
    <>
      <ListItemButton onClick={() => setExpanded(!expanded)} sx={{ padding: 0 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <Divider sx={{ width: '100%' }} />
          <Typography variant="h4" sx={{ mb: 2, mt: 2 }}>
            {title}
          </Typography>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Typography>{validityInterval}</Typography>
            <Typography sx={{ mb: 2, mt: 2 }}>{preview}</Typography>
            {approvedAt ? (
              <Chip label="Apstiprināts" sx={{ mb: 2, mt: 2, textTransform: 'uppercase', color: theme.palette.success.dark }} />
            ) : (
              <Chip label="Iesniegts" sx={{ mb: 2, mt: 2, textTransform: 'uppercase', color: theme.palette.warning.dark }} />
            )}
            <Box>
              <Button sx={{ m: 1 }} color="info" variant="contained" onClick={() => onView(id)}>
                Skatīt
              </Button>
              <Button sx={{ m: 1 }} variant="contained" disabled={disabled} onClick={() => onEdit(id)}>
                Labot
              </Button>
              <Button sx={{ m: 1 }} color="error" variant="contained" onClick={() => onDelete(id)}>
                Dzēst
              </Button>
            </Box>
          </Collapse>
        </Box>
      </ListItemButton>
    </>
  )
}

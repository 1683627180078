import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { ItemButton, InfiniteList } from '../../components'
import { EventsListItem } from '../../interfaces'
import { mapEventsList } from '../../utils'
import { DEFAULT_ENTRIES_PER_PAGE } from '../../constants'
import { Box, Button } from '@mui/material'
import { useDeleteEventMutation, useGetEventsListQuery } from '../../graphql'
import { useConfirmStore } from '../../store'

export const EventsHome = () => {
  const navigate = useNavigate()
  const [currentPage, setCurrentPage] = useState(1)

  const { data, loading, refetch } = useGetEventsListQuery()
  const [eventList, setEventList] = useState<EventsListItem[]>([])
  const [pagedEventList, setPagedEventList] = useState<EventsListItem[]>([])
  const [deleteEvent] = useDeleteEventMutation()
  const confirm = useConfirmStore((state) => state.confirm)

  useEffect(() => {
    if (data) {
      const list = mapEventsList(data)

      setEventList(list)
      setPagedEventList(list.slice(0, DEFAULT_ENTRIES_PER_PAGE))
    }
  }, [data])

  const onNewEvent = () => {
    navigate('/events/new')
  }

  const onEdit = (id: string) => {
    navigate('/events/edit/' + id)
  }

  const onView = (id: string) => {
    navigate('/events/item/' + id)
  }

  const onDelete = async (id: string) => {
    const response = await confirm({
      promptTitle: 'Vai tiešām dzēst šo pasākumu?',
      confirmButtonColor: 'error',
      confirmButtonLabel: 'Dzēst',
    })

    if (response && id) {
      deleteEvent({ variables: { id: id } }).then(() => {
        refetch()
      })
    }
  }

  const onLoadMore = () => {
    const nextPage = currentPage + 1
    const nextEntriesCount = nextPage * DEFAULT_ENTRIES_PER_PAGE

    setCurrentPage(nextPage)

    if (nextEntriesCount >= eventList.length) {
      setPagedEventList(eventList)
      return
    }

    setPagedEventList(eventList.slice(0, nextEntriesCount))
  }

  const renderListItem = (eventItem: EventsListItem) => {
    return (
      <ItemButton
        title={eventItem.title}
        id={eventItem.id}
        preview={eventItem.preview}
        disabled={eventItem.publishedAt !== null}
        onView={() => onView(eventItem.id)}
        onEdit={() => onEdit(eventItem.id)}
        onDelete={() => onDelete(eventItem.id)}
        validityInterval={eventItem.validityInterval}
        approvedAt={eventItem.publishedAt !== null}
      />
    )
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-around',
          position: 'fixed',
          zIndex: 2,
          bottom: 'env(safe-area-inset-bottom, 0)',
          left: 'env(safe-area-inset-left, 0)',
          right: 'env(safe-area-inset-right, 0)',
        }}
      >
        <Button sx={{ maxWidth: '1400px', m: 1, flexGrow: 1 }} variant="contained" onClick={onNewEvent}>
          Pievienot jaunu
        </Button>
      </Box>
      <InfiniteList loading={loading} renderFn={renderListItem} onLoadMore={onLoadMore} list={pagedEventList} />
    </>
  )
}

import { Typography } from '@mui/material'
import { Navigate } from 'react-router-dom'
import { QrButton, ResultWrap } from '../../components'
import { useVerifyStore } from '../../store'

export const ResultError = () => {
  const error = useVerifyStore((state) => state.error)

  if (error == null) {
    return <Navigate to="/" />
  }

  return (
    <ResultWrap backgroundColor="#FFCBD5">
      <Typography
        variant="h1"
        sx={{
          mt: 'auto',
          mb: 'auto',
          textTransform: 'uppercase',
          lineHeight: 1.5,
        }}
      >
        {error}
      </Typography>
      <QrButton />
    </ResultWrap>
  )
}

export const GRAPHQL_API_URL = process.env.REACT_APP_API_BASE_URL + '/graphql'

export const GRAPHQL_API_FILE_URL = process.env.REACT_APP_API_BASE_URL + '/rest/file'

export const DEFAULT_DATE_TIME_FORMAT = 'dd.MM.yyyy. HH:mm'

export const DEFAULT_ENTRIES_PER_PAGE = 10

export const DEFAULT_PHONE_AUTH_CODE_LENGTH = 6

export const REGISTER_SEND_TO_EMAIL = 'pilsetas@mobillytx.com'

export const MUNICIPALITIES_ID_MAP = process.env.REACT_APP_MUNICIPALITIES_IDS_MAP
  ? JSON.parse(process.env.REACT_APP_MUNICIPALITIES_IDS_MAP)
  : undefined

export const ADDRESS_API_URL = 'https://amk.viss.gov.lv/rest/address'

export const MAX_RICH_TEXT_LENGTH = 8000

import { Box, Button, Link, Typography } from '@mui/material'
import { REGISTER_SEND_TO_EMAIL } from '../../../constants'

interface RegisterProps {
  onBack: () => void
}

export const Register = ({ onBack }: RegisterProps) => {
  const onBackClick = () => {
    onBack()
  }

  return (
    <>
      <Box>
        <Typography component="span">Reģistrēt lietotāju</Typography>
      </Box>
      <Box>
        <Typography component="span">
          Lai reģistrētu lietotāju sūti e-pastu uz{' '}
          <Link type="email" href={'mailto:' + REGISTER_SEND_TO_EMAIL}>
            {REGISTER_SEND_TO_EMAIL}
          </Link>{' '}
          norādot uzņēmuma nosaukumu, reģistrācijas numuru un kontaktinformāciju
        </Typography>
      </Box>
      <Box>
        <Button
          sx={{ mt: 4, mr: 'auto', ml: 'auto', width: '100%' }}
          size="large"
          variant="contained"
          onClick={onBackClick}
          type="button"
        >
          Atpakaļ
        </Button>
      </Box>
    </>
  )
}

import { useState } from 'react'
import { useUserContext } from '../../store'
import { Http } from '@capacitor-community/http'
import { Capacitor } from '@capacitor/core'
import write_blob from 'capacitor-blob-writer'
import { Directory, Filesystem } from '@capacitor/filesystem'
import { GRAPHQL_API_FILE_URL } from '../../constants'

const characters = 'abcdefghijklmnopqrstuvwxyz'
const charactersLength = characters.length

const generateFoldername = (length = 16) => {
  let result = ''

  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }

  return result
}

export const useUploadGenericFiles = () => {
  const token = useUserContext((state) => state.token)

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const uploadGenericFiles = async (files: File[]) => {
    setLoading(true)
    setError(false)

    const request = files.map(async (file) => {
      if (Capacitor.getPlatform() === 'web') {
        const formData = new FormData()

        formData.append('file', file)

        return fetch(GRAPHQL_API_FILE_URL, {
          method: 'POST',
          body: formData,
          headers: {
            authorization: token ? `Token ${token}` : '',
          },
        }).then((response) => response.json())
      }

      /*
       * in order to bypass CORS, we use Http.uploadFile
       * as HTML input with type "file" returns Blob (File), but uploadFile expects native path and directory
       * Blob gets saved into Cache
       * and then upload is done from it through uploadFile method
       */
      const fileName = file.name || 'file'
      const path = `${generateFoldername()}/${fileName}`

      await write_blob({
        path,
        blob: file,
        directory: Directory.Cache,
        recursive: true,
        on_fallback: (error) => {
          throw error
        },
      })

      return Http.uploadFile({
        method: 'POST',
        url: GRAPHQL_API_FILE_URL,
        filePath: path,
        fileDirectory: Directory.Cache,
        name: 'file',
        headers: {
          authorization: token ? `Token ${token}` : '',
        },
      })
        .then((response) => {
          if (typeof response.data === 'object' && response.data !== null) {
            return response.data
          }

          throw response
        })
        .finally(() => {
          try {
            Filesystem.rmdir({
              path,
              directory: Directory.Cache,
              recursive: true,
            })
          } catch {}
        })
    })

    return Promise.all(request)
      .then((response) => {
        const uploadIds: string[] = response.map((entry) => entry.id)

        setLoading(false)
        return uploadIds
      })
      .catch(() => setError(true))
  }

  return {
    uploadGenericFiles,
    loading,
    error,
  }
}

import { FormEvent, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useUploadCameraFiles, useUploadGenericFiles } from '../../api'
import { EventForm, FullPageLoader } from '../../components'
import { GENERIC_ERROR } from '../../constants'
import { TagOutputPev, useEditEventMutation, useGetEventQuery, useGetEventTagsQuery } from '../../graphql'
import { AttachmentItem, CameraFile, EventEntry, StringObject, Tag, TextEditorRef } from '../../interfaces'
import { useSnackbarStore } from '../../store'
import { mapEvent, mapTags, remapFileList, validateEventForm } from '../../utils'

export const EventItemEdit = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const showSnackbar = useSnackbarStore((state) => state.showSnackbar)

  const [eventItem, setEventItem] = useState<EventEntry | null>(null)
  const [formErrors, setFormErrors] = useState<StringObject>({})
  const [fileList, setFileList] = useState<(File | CameraFile | AttachmentItem)[]>([])
  const [images, setImages] = useState<AttachmentItem[]>([])
  const [locationTags, setLocationTags] = useState<Tag[]>([])
  const [categoryTags, setCategoryTags] = useState<Tag[]>([])
  const [saving, setSaving] = useState(false)

  const { loading: loadingData, data: eventData } = useGetEventQuery({ variables: { id: id as string } })
  const { data: tagsData } = useGetEventTagsQuery({
    skip: !eventItem?.municipalityId,
    variables: { municipalityId: eventItem?.municipalityId },
  })

  const [editEvent] = useEditEventMutation()
  const { uploadCameraFiles } = useUploadCameraFiles()
  const { uploadGenericFiles } = useUploadGenericFiles()
  const editorRef = useRef<TextEditorRef>(null)

  useEffect(() => {
    if (eventData) {
      const [event] = mapEvent(eventData)

      setEventItem(event)
      if (event) {
        setFileList(event.images)
      }
    }
  }, [eventData])

  useEffect(() => {
    setImages(remapFileList(fileList))
  }, [fileList])

  useEffect(() => {
    if (tagsData && tagsData.publicEvents && tagsData.publicEvents.tags) {
      const [category, location] = mapTags(tagsData.publicEvents.tags as TagOutputPev[], 'public-events')

      setCategoryTags(category)
      setLocationTags(location)
    }
  }, [tagsData])

  const onChange = (event: EventEntry, errors: StringObject) => {
    setEventItem(event)
    setFormErrors(errors)
  }

  const errorHandler = () => {
    setSaving(false)
    showSnackbar(GENERIC_ERROR, { severity: 'error' })
  }

  const onSubmit = (event: FormEvent) => {
    event.preventDefault()

    if (!eventItem) {
      return
    }

    const [isInvalid, errors, startsAt, duration] = validateEventForm(eventItem, true)

    setFormErrors(errors)

    if (isInvalid) {
      return
    }

    const cameraFiles: CameraFile[] = []
    const webFiles: File[] = []
    const attachedFileIds: string[] = []

    for (const file of fileList) {
      if (file instanceof File) {
        webFiles.push(file)
        continue
      }

      if ('webPath' in file) {
        cameraFiles.push(file)
        continue
      }

      if (file.id) {
        attachedFileIds.push(file.id)
      }
    }

    const content = editorRef.current ? editorRef.current.getText() : ''

    setSaving(true)

    Promise.all([uploadGenericFiles(webFiles), uploadCameraFiles(cameraFiles)])
      .then(async ([uploadedWebFiles, uploadedCameraFiles]) => {
        return editEvent({
          variables: {
            id: eventItem.id,
            input: {
              schedule: {
                startsAt,
              },
              duration,
              location: {
                address: eventItem.address,
              },
              message: {
                title: {
                  lv: eventItem.title,
                },
                content: {
                  lv: content,
                },
                attachedFileIds: [...attachedFileIds, ...(uploadedWebFiles || []), ...(uploadedCameraFiles || [])],
                tagIds: eventItem.categoryTags.concat(eventItem.locationTags),
              },
            },
          },
        })
      })
      .then(() => {
        showSnackbar('Pasākums labots!', { severity: 'success' })
        navigate('/events')
      })
      .catch(() => errorHandler())
  }

  const onDeleteImage = (index: number) => {
    const copy = [...fileList]
    copy.splice(index, 1)
    setFileList(copy)
  }

  const onWebImage = (files: File[]) => {
    setFileList([...fileList, ...files])
  }

  const onAppImage = (file: CameraFile) => {
    setFileList([...fileList, file])
  }

  if (loadingData) {
    return <FullPageLoader />
  }

  return (
    <>
      {eventItem ? (
        <EventForm
          ref={editorRef}
          eventItem={eventItem}
          disabled={saving}
          formErrors={formErrors}
          images={images}
          locationTags={locationTags}
          categoryTags={categoryTags}
          onChange={onChange}
          onSubmit={onSubmit}
          onDeleteImage={onDeleteImage}
          onWebImage={onWebImage}
          onAppImage={onAppImage}
        />
      ) : (
        <></>
      )}
    </>
  )
}

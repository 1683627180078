import { useNavigate } from 'react-router-dom'
import { useEffect, useRef } from 'react'
import { App } from '@capacitor/app'

export const BackButton = () => {
  const navigate = useNavigate()

  const backPressedTimes = useRef(0)
  const timerId = useRef(0)

  useEffect(() => {
    App.addListener('backButton', () => {
      backPressedTimes.current++

      if (backPressedTimes.current === 2) {
        App.exitApp()
      }

      if (timerId.current) {
        clearTimeout(timerId.current)
      }

      timerId.current = window.setTimeout(() => {
        backPressedTimes.current--

        navigate(-1)
      }, 200)

      return () => {
        if (timerId) {
          clearTimeout(timerId.current)
        }
      }
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <></>
}

import { ApolloProvider } from './providers'
import { createTheme, ThemeProvider } from '@mui/material'
import { Core, ErrorBoundary, Init } from './containers'
import { BrowserRouter } from 'react-router-dom'
import { routesConfig } from './routesConfig'

import { ThemeOptions } from '@mui/material'
import { ConfirmDialog, UpdateCheck } from './components'
import { useRef } from 'react'
import { Capacitor } from '@capacitor/core'

const theme: ThemeOptions = {
  palette: {
    primary: {
      main: '#58595B',
    },
    secondary: {
      main: '#C6C5C5',
    },
  },
  typography: {
    fontFamily: 'sans-serif',
    h1: {
      fontSize: '2rem',
    },
    h2: {
      fontSize: '1.1rem',
      textTransform: 'uppercase',
      fontWeight: 400,
    },
    h4: {
      fontSize: '1.1rem',
      color: 'inherit',
    },
    body2: {
      color: '#7F7F7F',
    },
  },
}

function App() {
  const platform = useRef(Capacitor.getPlatform())

  return (
    <ThemeProvider theme={createTheme(theme)}>
      <BrowserRouter>
        <ErrorBoundary>
          <Init>
            <ApolloProvider>
              <Core routesConfig={routesConfig} />
            </ApolloProvider>
          </Init>
          <ConfirmDialog />
          {platform.current !== 'web' ? <UpdateCheck /> : <></>}
        </ErrorBoundary>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App

import React from 'react'
import { Box, Divider, IconButton, List, ListItem, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { NormalizedNavLink } from '../NormalizedNavLink'
import { FLEX_DIRECTION_COLUMN } from '../../style/sx'
import { EventIcon, ExitIcon, OffersIcon, ProfileIcon, QrIcon } from '../../icons'

const items = [
  {
    to: '/profile',
    label: 'Profils',
    icon: <ProfileIcon size={'medium'} color={'primary'} />,
  },
  {
    to: '/scanner',
    label: 'QR koda skenēšana',
    icon: <QrIcon size={'medium'} color={'primary'} />,
  },
  {
    to: '/offers',
    label: 'Piedāvājumi',
    icon: <OffersIcon size={'medium'} color={'primary'} />,
  },
  {
    to: '/events',
    label: 'Pasākumi',
    icon: <EventIcon size={'medium'} color={'primary'} />,
  },
  {
    to: '/logout',
    label: 'Iziet',
    icon: <ExitIcon size={'medium'} color={'primary'} sx={{ pl: '6px' }} />,
  },
]

interface MenuProps {
  onClose: () => void
}

export const Menu = ({ onClose }: MenuProps) => {
  return (
    <Box sx={{ width: '100vw', height: '100%' }}>
      <Box sx={{ minHeight: '72px', display: 'flex' }}>
        <IconButton size="large" onClick={onClose} aria-label="Aizvērt" sx={{ ml: 'auto', mr: 2, alignSelf: 'center' }}>
          <CloseIcon fontSize="large" />
        </IconButton>
      </Box>
      <Divider />
      <Box sx={[FLEX_DIRECTION_COLUMN, { justifyContent: 'space-between', height: 'calc(100% - 73px)' }]}>
        <List sx={{ width: '90%', ml: 'auto' }}>
          {items.map((item, index) => (
            <ListItem key={index}>
              <NormalizedNavLink to={item.to} onClick={onClose}>
                <Box component="span" sx={{ display: 'flex', mr: 2 }}>
                  {item.icon}
                </Box>
                <Typography component="span" color="inherit">
                  {item.label}
                </Typography>
              </NormalizedNavLink>
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  )
}

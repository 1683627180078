import { Alert, Box, List, ListItem } from '@mui/material'
import { CommentItem } from '../../interfaces'
import { Comment } from '../Comment'
import { SectionTitle } from '../SectionTitle'

interface CommentsProps {
  comments: CommentItem[] | undefined
}

export const Comments = ({ comments }: CommentsProps) => {
  return (
    <Box>
      <SectionTitle label="Lietotāju komentāri" />
      {comments && comments.length > 0 ? (
        <List disablePadding sx={{ mb: 2 }}>
          {comments.map((comment) => (
            <ListItem key={comment.id} sx={{ display: 'flex', flexDirection: 'column' }} disablePadding disableGutters>
              <Comment comment={comment} />
              {comment.comments && comment.comments.length > 0 ? (
                <List sx={{ width: '100%' }} disablePadding>
                  {comment.comments.map((reply) => (
                    <ListItem key={reply.id} disablePadding disableGutters>
                      <Comment comment={reply} inline />
                    </ListItem>
                  ))}
                </List>
              ) : (
                <></>
              )}
            </ListItem>
          ))}
        </List>
      ) : (
        <Alert severity="info" sx={{ mt: 2, mb: 2 }}>
          Nav pievienots neviens komentārs
        </Alert>
      )}
    </Box>
  )
}

import { useSvgParams } from '../../hooks'
import { SvgIcon } from '@mui/material'
import { SvgIconsProps } from '../../interfaces'

export const ProfileIcon = ({ color, size, sx }: SvgIconsProps) => {
  const [dimension, fill] = useSvgParams({ size, color })

  return (
    <SvgIcon viewBox="0 0 66 66" sx={[{ width: dimension, height: dimension }, sx || {}]}>
      <path
        fill={fill}
        d="M51.02 56.74c-1.24 0-2.25-1.01-2.25-2.25v-2.57c0-4.44-3.61-8.05-8.05-8.05H25.27c-4.44 0-8.05 3.61-8.05 8.05v2.57c0 1.24-1.01 2.25-2.25 2.25s-2.25-1.01-2.25-2.25v-2.57c0-6.92 5.63-12.55 12.55-12.55h15.45c6.92 0 12.55 5.63 12.55 12.55v2.57c0 1.24-1.01 2.25-2.25 2.25zM33 34.33c-6.92 0-12.55-5.63-12.55-12.55S26.08 9.23 33 9.23s12.55 5.63 12.55 12.55S39.92 34.33 33 34.33zm0-20.6c-4.44 0-8.05 3.61-8.05 8.05s3.61 8.05 8.05 8.05 8.05-3.61 8.05-8.05-3.61-8.05-8.05-8.05z"
      />
    </SvgIcon>
  )
}

import { Box } from '@mui/material'

interface ResultWrapProps {
  backgroundColor: string
  children: JSX.Element | JSX.Element[]
}

export const ResultWrap = ({ backgroundColor, children }: ResultWrapProps) => {
  return (
    <Box
      sx={{
        backgroundColor,
        height: '100%',
        m: -2,
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
      }}
    >
      {children}
    </Box>
  )
}

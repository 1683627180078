import { useEffect, useState } from 'react'
import { useTheme } from '@mui/material'
import { SvgIconsProps } from '../interfaces'

export const useSvgParams = ({ size, color }: SvgIconsProps) => {
  const theme = useTheme()
  const [dimension, setDimension] = useState('')
  const [fill, setFill] = useState('')

  useEffect(() => {
    const dimensionMap = {
      small: '2rem',
      medium: '2.5rem',
      large: '3rem',
    }

    setDimension(dimensionMap[size])
  }, [size])

  useEffect(() => {
    setFill(theme.palette[color].main)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [color])

  return [dimension, fill]
}
